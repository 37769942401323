/* 
.pay-sel-dev button:hover {
  box-shadow: 0 4px 3px 0 rgb(0 0 0 / 10%), 1px 8px 20px 0 rgba(0, 0, 0, 0.19);
}
 */

.pay-sources-drop-down {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  border-right: 7px solid #ededed;
}

.pay-sources-drop-down:focus {
  border: 1px solid #000;
  box-shadow: none;
}

.pay-sel-dev .selected-payment i {
  font-size: 20px;
  padding: 10px 5px;
}

.pay-sel-dev .selected-payment:focus {
  outline: 0;
  box-shadow: none;
}

.back-btn {
  color: #747474;
  cursor: pointer;
  font-size: 14px;
}

.back-btn:hover {
  color: #000;
}

.edit-card-payment-div {
  font-size: 14px;
  text-align: left;
  margin: 2rem;
}

.edit-card-payment-div-label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
  display: block;
  text-align: center;
  margin-bottom: 1rem;
}

.edit-card-payment-div .fa-credit-card {
  font-size: 25px;
  color: #ff7068;
  vertical-align: middle;
  margin-right: 10px;
}

.edit-card-payment-div .fa-circle {
  font-size: 4px;
  vertical-align: middle;
  margin-right: 1px;
}

.edit-card-payment-div .fa-pencil-alt {
  color: #ff7068;
}

.edit-card-payment-div .fa-pencil-alt:hover {
  color: #da2b22;
  cursor: pointer;
}

/* card interface */
.add-card-div .add-card {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.25rem;
  transition: 0.3s;
  background-color: #2d595b;
}

.add-card-div .add-card:focus {
  outline: 0;
  box-shadow: none;
}

.add-card-div .add-card:hover {
  filter: brightness(1.1);
}

.add-card-div label {
  margin-top: 8px !important;
}

.payment-method-title {
  text-align: center;
  font-size: 14px;
}

.total-order-info {
  border-top: 1px solid #d4d4d4;
  margin: 4rem 2rem 0 2rem;
  padding-top: 0.7rem;
}

.promo-code-check {
  margin-bottom: 1.5rem;
  color: #006dee;
  cursor: pointer;
  font-size: 13px !important;
}

.promo-code-link {
  font-size: 13px;
}

.promo-code-link:hover {
  text-decoration: underline;
}

.total-order-info .total-sec {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}

.total-order-info .order-total-wrapper {
  margin-top: 1.5rem !important;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}

.edit-card-payment-div-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-card-payment-div-select {
  width: 80%;
  margin-right: 10px;
}

.edit-card-payment-div-btn {
  width: 20%;
  border: 2px solid #666666;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
}

.edit-card-payment-div-btn:hover {
  background-color: #e5e5e5;
}

.checkout-pay-button {
  text-align: center;
  margin-top: 1.5rem;
}

.add-card-div {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
}

.save-card-btn {
  margin-top: 0.5rem;
}

/* Add Credit Card */
.eMdWNX {
  display: grid !important;
  position: unset !important;
  margin-left: 0 !important;
}

.eMdWNX:nth-child(3) {
  width: 48%;
  float: left;
}

.eMdWNX:nth-child(4) {
  width: 48%;
  float: right;
}

.eMdWNX .credit-card-input {
  position: unset !important;
  color: #222222;
  background-color: #ededed;
  border: none;
  border-radius: 0.25rem;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.jrjcHa {
  display: block !important;
  position: unset !important;
}

.lkUDGS {
  display: none !important;
}

.cLXUTa {
  display: block !important;
}

.jrjcHa.is-invalid {
  border: none;
}

.bByNba {
  margin: 0;
  margin-bottom: 10px;
}

.pay-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pay-select-radio {
  z-index: -1;
  position: absolute;
  opacity: 0;
}
.pay-select-radio:checked ~ label {
  border: 2px solid rgb(5, 147, 153);
  background-color: #e0e0e0;
  color: #000000;
}
.pay-select-radio:checked ~ label:before {
  will-change: transform, border-width, border-color;
  -webkit-animation: bubble 1s ease-in;
  animation: bubble 1s ease-in;
}
.pay-select-radio:checked ~ label:after {
  will-change: opacity, box-shadow;
  -webkit-animation: sparkles 700ms ease-in-out;
  animation: sparkles 700ms ease-in-out;
}
.pay-select-radio:checked ~ label > span {
  will-change: transform;
  border: 0;
  background-image: linear-gradient(to top right, #6e89ff, #4363ee);
  -webkit-animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
  animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
}
.pay-select-radio:checked ~ label > span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: #fff;
}

.pay-select-label {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 65px;
  grid-gap: 20px;
  width: 320px;
  height: 62px;
  padding: 0 20px;
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: transparent;
  transition: all 300ms ease-in;
  background: #ededed;
  font-weight: 500;
  font-size: 14px;
}
.pay-select-label:hover {
  border-color: #4062f6;
  background-color: rgba(97, 154, 234, 0.16);
}
.pay-select-label:before,
.pay-select-label:after {
  position: absolute;
  left: 29px;
  border-radius: 50%;
  content: "";
}
.pay-select-label:before {
  margin: -2rem;
  border: solid 2rem #545461;
  width: 4rem;
  height: 4rem;
  transform: scale(0);
}
.pay-select-label:after {
  margin: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
}
.pay-select-label > span {
  position: relative;
  display: inline-flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #bdbdbd;
  background-image: linear-gradient(to bottom, #cacaca, #cacaca);
}

.pay-card {
  text-align: right;
}

.pay-card svg {
  width: 45px;
  height: auto;
}

.pay-card img {
  width: 46px;
  height: auto;
}

.option {
  transition: 0.2s;
}

.pay-select-label:hover {
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%), 1px 4px 6px 0 rgb(0 0 0 / 19%)
}

.option:not(:last-child) {
  margin-bottom: 4px;
}

@-webkit-keyframes radio {
  0%,
  17.5% {
    transform: scale(0);
  }
}

@keyframes radio {
  0%,
  17.5% {
    transform: scale(0);
  }
}

@media (max-width: 520px) {
  .edit-card-payment-div-inner {
    flex-wrap: wrap;
    justify-content: center;
  }

  .edit-card-payment-div-select {
    width: 100%;
  }

  .edit-card-payment-div-btn {
    width: 50%;
    margin-left: 0;
    margin-top: 1rem;
  }

  .total-order-info {
    margin: 3rem 0rem 0 0rem;
  }
}
