@import url("https://fonts.googleapis.com/css2?family=Passero+One&display=swap");

:root {
  --main-background-color: #2d595b;
}

html {
  scroll-behavior: smooth;
}

.menu-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  position: relative;
}

.menu-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.3)),
      to(rgba(0, 0, 0, 0.3))
    ),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(transparent));
  z-index: 0;
}

.res-home-notice {
  max-width: 900px;
  margin: auto;
  background-color: #ffffff;
  border: 2px solid #006dee;
  border-radius: 8px;
  padding: 15px 60px;
}

.res-home-notice h4 {
  text-align: center;
  font-size: 17px;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.res-home-notice p {
  margin-bottom: 0;
  font-size: 15px;
}

.menu-header .header-rest-main-div {
  max-width: 700px;
  margin: auto;
  margin-top: 105px;
}

.rest-store-details .cuisines-para {
  margin-bottom: 8px;
}

.rest-store-details .cuisines i {
  font-size: 6px;
  vertical-align: 3px;
  margin-right: 4px;
  color: #8c8c8c;
}

.rest-store-details .cuisines {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-right: 10px;
  display: inline-block;
}

.menu-header .header-rest-info-div {
  height: auto;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  z-index: 2;
  text-align: center;
}

.menu-header .header-rest-info-div .rest-name {
  color: #ffffff;
  margin-top: 0;
  font-size: 40px;
  margin-bottom: 2rem;
}

.rest-logo-main {
  max-width: 80px;
  margin-bottom: 1rem;
}

.menu-header .header-rest-info-div .CTA-mobile {
  display: none;
}

.menu-header .header-rest-info-div .CTA-non-mobile {
  display: inline;
}

.menu-header .header-rest-info-div .CTA-mobile,
.menu-header .header-rest-info-div .CTA-non-mobile {
  background: #e6e6e6;
  font-size: 12px;
  color: #000;
  padding: 4px 9px;
  border-radius: 25px;
  vertical-align: middle;
  text-decoration: none;
  font-weight: 600;
  height: 20px;
}

.menu-header .header-rest-info-div .address {
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  font-weight: 500;
}

.res-open-lable,
.res-closed-lable {
  font-size: 14px;
  border-radius: 5px;
  padding: 2px 8px;
}

.res-more-info {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.5rem;
  display: inline-block;
  text-decoration: underline;
}

.res-home-order-modification {
  padding: 2rem 0;
}

.payment-methods-icon svg {
  width: 42px;
  margin-right: 10px;
}

.res-home-order-modification .order-time-change {
  margin-bottom: 0;
}

.menu-header .header-rest-info-div .rest-desc {
  margin-top: 15px;
  height: 40px;
  font-size: 14px;
  color: #737373;
  overflow: hidden;
  margin-bottom: 0;
}

.menu-header .delivery-details-div {
  position: relative;
  height: auto;
  border: 2px solid;
  padding: 15px;
  margin-top: 32px;
  border-radius: 5px;
}

.menu-header .delivery-details-div .del-heading {
  position: absolute;
  font-size: 20px;
  top: -18px;
  left: 15px;
  background: #fff;
  padding: 0 10px;
  font-weight: 600;
}

.menu-header .delivery-details-div .info {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

#order-accepting-hours {
  display: inline;
  padding-right: 10px;
}

.filter-bar {
  top: 65px;
  overflow: auto;
  white-space: nowrap;
}

.filter-badge {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  transform: translateZ(0px);
  padding: 9px 20px;
  border-radius: 5px;
  line-height: 50px;
}

.active-filter {
  background: #000;
  color: #fff;
  padding: 9px 20px;
  margin-left: 15px;
}

.active-filter:hover {
  color: #fff !important;
}

.filter-badge:hover {
  color: inherit;
  text-decoration: none;
}

.menu-items-sec .menu-headings {
  position: relative;
  font-weight: 900;
  font-size: 30px;
}

.rest-details-map {
  height: 400px;
  background-color: #dee2e6;
}

.rest-details-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #b15555;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-animation-name: bounce;
  -moz-animation-name: bounce;
  -o-animation-name: bounce;
  -ms-animation-name: bounce;
  animation-name: bounce;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  -ms-animation-duration: 1s;
  animation-duration: 1s;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #302558;
  position: absolute;
  border-radius: 50%;
}
.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
  -moz-transform: rotateX(55deg);
  -o-transform: rotateX(55deg);
  -ms-transform: rotateX(55deg);
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: pulsate 1s ease-out;
  -moz-animation: pulsate 1s ease-out;
  -o-animation: pulsate 1s ease-out;
  -ms-animation: pulsate 1s ease-out;
  animation: pulsate 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-box-shadow: 0 0 1px 2px #b15555;
  box-shadow: 0 0 1px 2px #b15555;
  -webkit-animation-delay: 1.1s;
  -moz-animation-delay: 1.1s;
  -o-animation-delay: 1.1s;
  -ms-animation-delay: 1.1s;
  animation-delay: 1.1s;
}
@-moz-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-o-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    -moz-transform: scale(0.1, 0.1);
    -o-transform: scale(0.1, 0.1);
    -ms-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    -moz-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@-moz-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-webkit-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@-o-keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    -webkit-transform: translateY(-2000px) rotate(-45deg);
    -moz-transform: translateY(-2000px) rotate(-45deg);
    -o-transform: translateY(-2000px) rotate(-45deg);
    -ms-transform: translateY(-2000px) rotate(-45deg);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
    -webkit-transform: translateY(30px) rotate(-45deg);
    -moz-transform: translateY(30px) rotate(-45deg);
    -o-transform: translateY(30px) rotate(-45deg);
    -ms-transform: translateY(30px) rotate(-45deg);
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
    -moz-transform: translateY(-10px) rotate(-45deg);
    -o-transform: translateY(-10px) rotate(-45deg);
    -ms-transform: translateY(-10px) rotate(-45deg);
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
    -moz-transform: translateY(0) rotate(-45deg);
    -o-transform: translateY(0) rotate(-45deg);
    -ms-transform: translateY(0) rotate(-45deg);
    transform: translateY(0) rotate(-45deg);
  }
}

.rest-store-details {
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.store-details-wrapper {
  margin-bottom: 4rem;
}

.rest-store-details-full .CTA-mobile {
  color: #212529;
  text-decoration: none;
}

.rest-store-details-desc {
  margin-top: 1rem;
}

.rest-store-details-hours span {
  margin-left: 0.5rem;
}

.rest-store-details-open-hours-table {
  display: flex;
}

.rest-store-details-full {
  margin-top: 3rem;
}

.rest-store-details-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.rsd-details-block {
  margin-top: 2rem;
}

.minimum-order-values-wrapper {
  display: flex;
  margin-top: 1rem;
}

.minimum-order-values-block {
  margin-bottom: 1rem;
  margin-right: 3rem;
  display: flex;
}

.minimum-order-values-block .radio-icon {
  width: 20px;
  margin-right: 15px;
}

.minimum-order-values-block p {
  margin-bottom: 0;
}

.rest-contact-block {
  display: flex;
  align-items: baseline;
}

.disabled-select-option {
  color: rgb(170, 170, 170);
  pointer-events: none;
}

.schedule-onclosed-wrapper {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.schedule-onclosed-wrapper:hover {
  text-decoration: underline;
}

/* cards */
.menu-item-card {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.menu-item-card-inner {
  background-color: #f2f2f2;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.menu-item-card-empty {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.menu-item-card .img-div {
  position: relative;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
}

.menu-item-card .img-div img {
  width: 100%;
  border-radius: 5px 5px 0 0;
  height: 100%;
  object-fit: cover;
}

.menu-item-card .img-div .out-of-stock {
  position: absolute;
  color: rgb(46, 46, 46);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  font-size: 14px;
  padding: 3px 7px;
  top: 8px;
  right: 10px;
}

.menu-item-card-empty .out-of-stock {
  position: absolute;
  color: rgb(46, 46, 46);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  font-size: 14px;
  padding: 3px 7px;
  top: 8px;
  right: 25px;
}

.menu-item-card .name,
.menu-item-card-empty .name {
  font-weight: 600;
  margin: 10px;
  font-size: 16px;
}

.menu-item-card .desc,
.menu-item-card-empty .desc {
  margin: 0 10px;
  font-size: 14px;
  color: #717171;
  flex-grow: 1;
}

.menu-item-card-empty .desc {
  min-height: 25px;
}

.menu-item-card .price,
.menu-item-card-empty .price {
  color: rgb(51, 51, 51);
  margin: 0px;
  font-size: 15px;
  font-weight: 900;
  position: absolute;
  bottom: 10px;
}

.menu-item-card .discounted-price,
.menu-item-card-empty .discounted-price {
  color: rgb(51, 51, 51);
  margin: 0px;
  font-size: 12px;
  /* font-weight: 900; */
  position: relative;
  bottom: 12px;
  text-decoration: line-through;
}

.card-action-price-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 15px;
  height: 33px;
}

.menu-item-card .dissable-cart-btn,
.menu-item-card-empty .dissable-cart-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #e6e6e6;
  color: rgb(115, 115, 115);
  padding: 8px 10px 7px 10px;
  border-radius: 5px;
  border: 0;
  font-size: 12px;
  font-weight: 600;
  cursor: not-allowed;
}

.menu-item-card .cart-btn,
.menu-item-card-empty .cart-btn {
  position: absolute;
  color: #fff;
  padding: 8px 10px 7px 10px;
  border-radius: 5px;
  border: 0;
  bottom: 10px;
  font-size: 12px;
  right: 10px;
  font-weight: 600;
  z-index: -1;
}

.menu-item-card .cart-btn:hover,
.menu-item-card-empty .cart-btn:hover {
  background: #e23131;
  cursor: pointer;
}

.menu-item-card .cart-btn:focus,
.menu-item-card-empty .cart-btn:focus {
  outline: 0;
}

/* map modal */
.map-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 0, 0);
  z-index: 1;
  background: #fff !important;
  padding: 1px 8px 4px 10px !important;
  border-radius: 50%;
  opacity: 1;
  font-weight: 400;
  font-size: 30px;
}

.map-close:focus {
  outline: 0;
}

.card-action-div {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 5px;
}

.see-more-link {
  font-size: 12px;
  line-height: 14px;
  color: #006dee;
  cursor: "pointer";
}

.category-wrapper {
  padding: 40px 0 0 0;
}

.menu-cards-container {
  margin-bottom: 15px;
}

.addons-loading-spinner {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f7f7f7;
}

.addons-loading-spinner .spinner-border {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  color: #808080;
}

.spinner-color-addon {
  color: #cacaca;
}

.dine-in-main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}

.select-dine-in-table-modal-body {
  padding: 1rem;
}

.select-table-container {
  position: relative;
  height: 45px;
  width: 100px;
  margin: 5px;
}

.table-select-radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.table-select-radio-button:checked + .table-select-radio-tile {
  border-color: var(--main-background-color, #2d595b);
  box-shadow: 0 0 0 1px var(--main-background-color, #2d595b) inset;
  background-color: var(--main-background-color, #2d595b);
}

.table-select-radio-button:checked
  + .table-select-radio-tile
  .table-select-radio-tile-label {
  color: #ffffff;
  font-weight: 600;
}

.table-select-radio-button:hover + .table-select-radio-tile {
  border-color: #b7b7b7;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: all 300ms;
}

.table-select-radio-button:checked:hover + .table-select-radio-tile {
  border-color: var(--main-background-color, #2d595b);
  box-shadow: 0 0 0 1px var(--main-background-color, #2d595b) inset,
    0 2px 12px rgba(0, 0, 0, 0.1);
}

.table-select-radio-tile {
  align-items: center;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 45px;
  justify-content: center;
  transition: all 300ms;
  width: 100px;
}

.table-select-radio-tile-label {
  color: #484848;
  font-size: 15px;
  text-align: center;
  margin: 0;
}

.table-select-action-buttons {
  margin-top: 2rem;
  text-align: center;
}

.table-select-action-buttons button {
  margin: 0 3px;
}

.table-select-button {
  color: #fff;
  padding: 8px 15px 7px 15px;
  border-radius: 5px;
  border: 0;
  bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.table-select-button:disabled {
  background: #e6e6e6 !important;
  color: rgb(115, 115, 115);
  padding: 8px 15px 7px 15px;
  border-radius: 5px;
  border: 0;
  bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: default;
}

.dine-in-selectbox {
  width: 80%;
}

@media (max-width: 975px) {
  .menu-header {
    height: auto;
    margin-bottom: unset !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .menu-header .header-rest-info-div {
    border-radius: 0;
  }

  .menu-header .header-rest-info-div .rest-desc {
    height: auto;
  }

  .minimum-order-values-wrapper {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .filter-bar {
    top: 65px;
  }

  .col-social {
    width: 100%;
  }

  .col-rest-about {
    width: 100%;
    text-align: left;
    margin-top: 2rem;
    font-size: 0.95rem;
  }

  .social-dev {
    padding-top: 0;
  }

  .rsd-minimum-order-value.rsd-details-block {
    margin-bottom: 1rem;
  }

  .rsd-delivering-to.rsd-details-block {
    display: inline-block;
    margin-right: 4rem;
  }

  .rsd-delivering-fee.rsd-details-block {
    display: inline-block;
  }
  .hide-on-mobile {
    display: none;
  }
  .shopping-cart .cart-badge {
    top: -10px !important;
    left: 12px !important;
  }
  .shopping-cart {
    margin-bottom: -5px !important;
  }
  .user-display-btn .dropdown-toggle:after {
    display: none;
  }
  .user-display-btn {
    padding: 8px 8px;
  }
  .shopping-cart .fa-shopping-cart {
    font-size: 23px;
  }
}

@media (max-width: 575px) {
  .menu-header .delivery-details-div {
    margin-top: 30px;
  }

  .menu-header .header-rest-info-div {
    height: auto;
    box-shadow: none;
  }

  .menu-header .header-rest-info-div .CTA-mobile {
    display: inline-block;
    cursor: pointer;
  }

  .menu-header .header-rest-info-div .CTA-non-mobile {
    display: none;
  }

  .menu-header .header-rest-info-div .CTA-mobile,
  .menu-header .header-rest-info-div .CTA-non-mobile {
    padding: 3px 9px 5px 9px;
  }

  #order-accepting-hours {
    display: block;
  }
  .menu-header .header-rest-main-div {
    margin-top: 180px;
  }
  .menu-item-card-inner {
    flex-direction: row;
  }
  .card-action-price-cart {
    margin-top: 10px;
  }
  .menu-item-card .img-div {
    height: 100%;
    width: 133px;
    max-width: 133px;
    min-width: 133px;
    border-radius: 5px;
    min-height: auto;
    max-height: unset;
  }
  .menu-item-card .price,
  .menu-item-card-empty .price {
    font-size: 15px;
  }
  .menu-item-card-empty .name {
    margin-left: 10px;
    margin-right: 10px;
  }
  .menu-item-card-empty .card-action-price-cart {
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-bar {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .menu-item-card-empty .out-of-stock {
    top: 23px;
  }
  .menu-item-card .desc,
  .menu-item-card-empty .desc {
    max-height: 46px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .menu-item-card .img-div .out-of-stock {
    right: 21px;
    font-size: 13px;
  }
  .menu-item-card .name,
  .menu-item-card-empty .name {
    font-size: 14px;
  }
  #menu-item-details-modal-close span {
    vertical-align: 2px !important;
    color: black;
  }
  .menu-header .header-rest-info-div .address {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .menu-item-card span {
    padding: 5px 7px 3px 7px;
  }

  .menu-item-card .dissable-cart-btn,
  .menu-item-card-empty .dissable-cart-btn {
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 11px;
  }

  .filter-badge {
    font-size: 13px;
  }
}

@media (max-width: 420px) {
  .menu-item-card .cart-btn,
  .menu-item-card-empty .cart-btn {
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 11px;
  }

  .menu-header .header-rest-info-div .rest-name {
    font-size: 32px;
    margin-bottom: 1rem;
  }

  .menu-item-card .img-div {
    height: 100%;
    max-width: 90px;
    min-width: 90px;
    border-radius: 5px;
    min-height: auto;
    max-height: unset;
  }

  .menu-item-card .img-div .out-of-stock {
    right: 13px;
    font-size: 10px;
  }

  .rest-logo-main {
    max-width: 56px;
  }
  .rest-store-details-desc {
    font-size: 13px;
  }

  .rest-store-details .cuisines {
    font-size: 13px;
  }

  .rest-store-details {
    margin-top: 2rem;
  }
  .rest-store-details-title {
    font-size: 14px;
  }

  .minimum-order-values-block p,
  .rest-contact-block p,
  .rest-contact-block a,
  .rsd-delivering-to.rsd-details-block,
  .rsd-delivering-fee.rsd-details-block {
    font-size: 14px;
  }

  .rest-contact-block i {
    font-size: 13px;
  }

  .payment-methods-icon svg {
    width: 36px;
  }
  .minimum-order-values-block .radio-icon {
    width: 17px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .menu-item-card .img-div {
    min-height: 150px;
    max-height: 150px;
  }
}

@media (min-width: 576px) and (max-width: 990px) {
  .menu-item-card .img-div {
    min-height: 150px;
    max-height: 150px;
  }
}

@media (min-width: 651px) and (max-width: 768px) {
  .menu-item-card .desc {
    min-height: 38px;
    height: auto;
  }

  .menu-item-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (max-width: 350px) {
  .menu-header .delivery-details-div {
    margin-top: 30px;
  }
}
