.user-details-form label,
.guest-details-in label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.user-details-form .form-control:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #000;
}

.react-tel-input {
  width: 300px;
  margin: auto;
}

.user-details-form select {
  font-size: 15px;
}

.user-details-form select:hover {
  cursor: pointer;
}

.user-details-form select option {
  font-size: 14px;
}

.add-address-btn {
  background: #ffffff;
  border: 0;
  padding: 7px 0;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 70px;
  border-radius: 5px;
}

.add-address-btn:hover {
  color: #e43026;
}

.add-address-btn:focus {
  outline: 0;
  box-shadow: none;
}

.add-phone-btn {
  display: block;
  background: #ffffff;
  border: 0;
  padding: 7px 0 0 0;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 15%;
  border-radius: 5px;
  text-align: left;
}

/* .add-phone-btn:hover {
  color: #e43026;
} */

.add-phone-btn:focus {
  outline: 0;
  box-shadow: none;
}

.styles_react-code-input__CRulA input:nth-child(1) {
  border: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(2) {
  border-top: 1.5px solid #f5756e !important;
  border-bottom: 1.5px solid #f5756e !important;
  border-right: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(3) {
  border-top: 1.5px solid #f5756e !important;
  border-bottom: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(4) {
  border: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:focus {
  border: 2px solid #505050 !important;
}

.select-city-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 15px;
  background: #ff6961;
  color: #fff;
  border-radius: 3px;
  padding: 2px 10px;
  margin-bottom: 0;
  margin-top: -35px;
  cursor: pointer;
}

.select-city-btn:hover {
  background: #e63b32;
}

.save-address {
  width: 50%;
  color: #fff;
  border: 0;
  margin-top: 6px;
  margin-bottom: -24px;
  background: #2d595b;
  padding: 7px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0.25rem;
}

.save-address:focus {
  outline: 0;
  box-shadow: none;
}

.send-to-verify {
  background: #2d595b;
  color: #fff;
  width: 50%;
  border: 0;
  padding: 7px;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
}

.send-to-verify-button {
  text-align: center;
}

.send-to-verify:focus {
  outline: 0;
  box-shadow: none;
}

.send-code-again {
  text-align: center;
  color: #ff7068;
  margin-top: 15px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 13px;
}

.send-code-again:hover {
  text-decoration: underline;
}

.your-details-mobile-no p {
  background-color: #ededed;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.4rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.your-details-address-no p {
  background-color: #ededed;
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.4rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.add-phone-btn-guest {
  background-color: #ededed;
  display: block;
  width: 100%;
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.4rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-radius: 0.25rem;
  border: none;
}

.add-phone-btn-guest:focus {
  outline: none;
}
.your-details-mobile-no .text-success {
  float: right;
  margin-top: 2px;
}

#addressModal {
  font-size: 17px;
}

.yd-label-action-btn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.yd-label-action-btn button {
  text-align: right;
  padding-right: 4px;
  font-weight: 500;
  color: #006dee;
}

.your-details-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.your-details-title h6 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

.react-tel-input .form-control {
  color: #222222;
  background-color: #ededed;
  border: none;
  border-radius: 0.25rem;
}

.react-tel-input .form-control:focus {
  border: none;
}

.react-tel-input .flag-dropdown {
  border: none;
  background-color: #ededed;
}

.react-tel-input .flag-dropdown:hover .selected-flag:hover {
  background-color: #ededed;
}

.mobile-number-popup-display-title {
  margin-bottom: 5px;
  font-size: 13px;
  color: #828282;
}

.mobile-number-popup-display {
  font-size: 14px;
  color: #333333;
  margin-bottom: 0;
}

.mobile-number-popup-display-wrapper {
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem;
}

.mobile-number-popup-display-edit {
  margin-bottom: 0;
  margin-left: 7px;
  font-size: 14px;
  color: #2979ff;
  cursor: pointer;
}

.mobile-number-popup-display-edit:hover {
  text-decoration: underline;
}

.otp-sub-title {
  margin-bottom: 10px;
  font-size: 14px;
  color: #828282;
}

.map-save-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.map-save-wrapper-inner {
  width: 50%;
}

.save-address.map-view {
  width: 100%;
}

.go-back-map-view {
  font-size: 14px;
  cursor: pointer;
}

.go-back-map-view:hover {
  text-decoration: underline;
  color: #2979ff;
}

@media (max-width: 520px) {
  .send-to-verify {
    width: 75%;
  }
}
