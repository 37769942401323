/* navbar */
.navbar-rest-name:hover {
  text-decoration: none;
}

.navbar-rest-name {
  display: block;
}

.delivery-pickup-dine-select {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.delivery-pickup-dine-select p {
  padding-left: 15px;
  margin-bottom: 0;
  line-height: 30px;
}

.delivery-pickup-dine-select p:hover {
  background: rgb(226, 226, 226);
}

.navbar-nav .shopping-cart {
  position: relative;
  width: 50px;
}

.logged-user {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.user-name {
  color: #fff;
  font-size: 13px;
  padding-top: 5px;
  padding-right: 10px;
}

.login-btn {
  color: #fff;
  padding: 10px;
  background-color: hsla(0, 0%, 51%, 0.41);
  border-radius: 5px;
  cursor: pointer;
  z-index: 999999 !important;
}

.login-btn i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 4px;
}

.shopping-cart {
  position: relative;
  margin-right: 1.5rem;
  cursor: pointer;
  margin-bottom: -6px;
}

.shopping-cart .fa-shopping-cart:hover {
  transform: scale(1.05);
}

.shopping-cart .cart-badge {
  position: absolute;
  color: #fff;
  padding: 0px 7px;
  border-radius: 50%;
  top: -10px;
  left: 12px;
  font-size: 14px;
  font-weight: 600;
}

.shopping-cart .fa-shopping-cart {
  font-size: 25px;
}

.cart-sec {
  max-width: 570px;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 6px;
}

.cart-sec.popup {
  width: 100%;
  padding: 0;
}

h5.cart-heading {
  margin: 0;
  font-size: 17px;
}

.cart-sec .cart-heading {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  color: #222222;
}

.cart-sec .deliver-from {
  font-size: 17px;
}

.cart-desktop {
  display: block;
}

.cart-sec .item-table tr td {
  border: 0;
}

.cart-sec .item-table tr td:nth-child(1) {
  padding-left: 10px;
  padding-right: 3px;
  font-weight: 600;
  font-size: 14px;
  width: 30px;
}

.cart-sec .item-table tr td:nth-child(2) {
  min-width: 120px;
  color: #222222;
  font-weight: 500;
  padding-top: 0.9rem;
  font-size: 14px;
}

.cart-sec .item-table tr td:nth-child(3) {
  font-weight: 600;
  font-size: 14px;
  color: #757575;
  min-width: 100px;
  text-align: right;
  padding-top: 0.9rem;
}

.cart-sec .item-table tr td:first-child {
  border-radius: 6px 0 0 6px;
}

.cart-sec .item-table tr td:last-child {
  border-radius: 0 6px 6px 0;
}

.cart-sec .item-table tr td:nth-child(4) {
  min-width: 30px;
  padding-right: 0;
  text-align: right;
}

.order-qty {
  padding: 4px 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  border-right: 4px solid #e9ecef;
}

.cart-sec.popup .item-table tr td:nth-child(1) {
  padding-left: 0;
  padding-right: 3px;
  font-weight: 600;
  font-size: 14px;
  width: 26px;
}

.cart-sec.popup .item-table tr td:nth-child(2) {
  min-width: 120px;
}

.cart-sec.popup .item-table tr td:nth-child(3) {
  font-weight: 600;
  font-size: 15px;
  color: #757575;
  min-width: 100px;
  text-align: right;
}

.cart-sec.popup .item-table tr td:nth-child(4) {
  min-width: 30px;
  padding-right: 0;
}

.cart-sec .dec-btn,
.cart-sec .inc-btn {
  color: #fff;
  padding: 3px 7px;
  border: 0;
  font-size: 14px;
  border-radius: 50%;
}

.cart-sec .dec-btn:hover,
.cart-sec .inc-btn:hover {
  background: rgb(82, 82, 82);
}

.cart-sec .dec-btn:focus,
.cart-sec .inc-btn:focus {
  outline: 0;
}

.cart-sec .qty {
  color: #000;
  padding: 0px 7px;
  font-weight: 600;
  margin: 0 5px;
}

.cart-sec .item-table .fa-trash-alt {
  color: #ef7272;
  cursor: pointer;
}

.cart-sec .item-table .fa-trash-alt:hover {
  color: red;
}

.cart-sec .total-sec {
  font-size: 14px;
}

.cart-sec .total-sec .col:nth-child(2) {
  text-align: right;
  padding-bottom: 10px !important;
}

.cart-sec .disabled-btn {
  background: #dcdcdc;
  color: #fff;
  border: 0;
  width: 100%;
  padding: 8px;
  margin-top: 6px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

.checkout-btn {
  text-align: center;
}

.cart-sec .checkout {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.25rem;
  transition: 0.3s;
  background-color: #2d595b;
}

.cart-sec .checkout:hover {
  filter: brightness(1.1);
}

.cart-sec.popup .checkout {
  color: #fff;
  border: 0;
  width: 100%;
  padding: 8px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 600;
}

.cart-sec.popup .checkout:hover {
  filter: brightness(1.1);
}

.additional-info-text-limit {
  width: 100%;
  display: block;
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  margin-top: 5px;
}

/* .cart-sec .checkout:hover {
  opacity: 0.8;
} */

.cart-sec .checkout:focus {
  box-shadow: none;
  outline: 0;
}

/* cart in mobile view */
.cart-mobile {
  display: none;
}

.cart-mobile .cart-item-name {
  padding-left: 0;
  font-weight: 600;
  font-size: 14px;
  min-width: 230px;
}

.cart-mobile .item-price {
  font-weight: 600;
  font-size: 15px;
  color: #757575;
  min-width: 100px;
  text-align: right;
  margin-bottom: 0;
}

.cart-mobile .item-del {
  color: #ef7272;
  cursor: pointer;
  margin-bottom: 40px;
}

.cart-mobile .qty {
  color: #545454;
  padding: 0px 7px;
  font-weight: 500;
  font-size: 15px;
  margin: 0 4px;
}

.go-back-action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-order-total {
  font-weight: 600;
  font-size: 15px;
  color: #222222;
  padding-right: 0.75rem;
}

.match-min-order-checkbox {
  text-align: center;
}

.match-min-order-checkbox label {
  font-size: 12px;
  color: #006dee;
  margin: 0;
}

.match-min-order-checkbox input {
  margin-right: 6px;
  vertical-align: middle;
}

.min-height-viewport {
  min-height: 75.7vh !important;
}

.cart-details-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
}

.cart-details-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 270px;
}

.cart-popup .item-table tr {
  border-bottom: 1px solid #f6f6f6;
}

.cart-sec .checkout:disabled,
.cart-sec .checkout[disabled],
.schedule-btn[disabled] {
  background-color: #cccccc !important;
  color: #666666;
}

.page-background-grey {
  background-color: #f2f2f2;
}

.padding-top-bottom-25 {
  padding: 25px 0;
}

.go-back-action-buttons a {
  font-size: 13px;
}

.go-back-action-buttons a:hover {
  text-decoration: underline !important;
}

.go-back-action-buttons a i {
  margin-right: 8px;
  font-size: 14px;
  transition: 0.2s;
}

.go-back-action-buttons a:hover i {
  color: #222222;
  transform: translateX(-2px);
}

.forgot-password-main .update-bank-model-heading,
.forgot-password-main .login-or-reg-buttons {
  margin: 0;
}

.empty-cart-notify {
  text-align: center;
}

.remove-cart-item {
  display: block;
  font-size: 10px;
  margin-top: 7px;
  font-weight: 500;
  text-decoration: underline;
  color: #2d595b;
}

.remove-cart-item span {
  cursor: pointer;
}

.remove-cart-item span:hover {
  color: #006dee;
}

.total-sec.subtotal {
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.cart-items-popup-display .container {
  padding-left: 0;
  padding-right: 0;
  padding: 20px;
}

.cart-items-popup-display > .close-cart {
  position: absolute;
  top: 6px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.cart-main {
  position: fixed;
  top: 65px;
  right: 0;
  background-color: #ffffff;
  max-width: 500px;
  min-width: 500px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-cart span:hover {
  color: red;
}

.cus-con-for-all.cart-main {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0 0 0 6px;
  max-height: 73vh;
  overflow-y: auto;
  cursor: default;
}

/* custom scrollbar */
.cus-con-for-all.cart-main::-webkit-scrollbar {
  width: 20px;
}

.cus-con-for-all.cart-main::-webkit-scrollbar-track {
  background-color: transparent;
}

.cus-con-for-all.cart-main::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cus-con-for-all.cart-main::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.finalized-order-info {
  display: flex;
  color: inherit;
  margin-left: 2rem;
  font-size: 0.95rem;
}

.finalized-order-type {
  margin-right: 2rem;
}

.finalized-order-type i,
.finalized-order-time i {
  margin-right: 0.6rem;
}

.menu-item-image img {
  object-fit: contain;
  width: 100%;
  max-height: 300px;
}

.menu-item-image {
  min-height: min-content;
  max-height: min-content;
  width: 100%;
  overflow: hidden;
  text-align: center;
  border-radius: 0.4rem;
  background-color: #ededed;
}

.menu-item-details-popup {
  padding: 0;
  border-radius: 0.5rem;
}

.menu-item-details-modal-body {
  position: relative;
}

#menu-item-details-modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #535353;
  background-color: #ffffff;
  opacity: 1;
  z-index: 1000;
}

#menu-item-details-modal-close:hover {
  background-color: rgb(212 212 212);
}

#menu-item-details-modal-close:focus {
  outline: 0;
}

#menu-item-details-modal-close span {
  vertical-align: 2px;
  font-size: 30px;
}

.addon-error {
  font-size: 13px;
  margin-bottom: 1rem;
  display: block;
  color: red;
}

.menu-item-details-modal-body-inner {
  padding: 15px;
}

.menu-item-details-description p {
  font-size: 14px;
  line-height: 1.5;
}

.addon-price {
  font-weight: 400;
}

.item-details-cart-btn {
  color: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  border: 0;
  right: 10px;
  bottom: 12px;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
}

.item-details-cart-btn:hover,
.item-details-cart-count:hover {
  filter: brightness(1.07);
}

.add-to-cart-btn-total {
  font-weight: 500;
}

.menu-item-details-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border-top: 1px solid #ededed;
  padding: 1rem 15px 0;
  margin-left: -15px;
  margin-right: -15px;
}

.menu-item-couter {
  display: flex;
  align-items: center;
}

.add-to-cart-btn {
  width: 75%;
}

.menu-item-couter {
  width: 20%;
}

.menu-item-details-title h6 {
  line-height: 1.5;
}

.item-details-cart-count {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  outline: 0;
  border: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.item-details-cart-count:focus {
  outline: 0;
}

.count-display {
  margin: 0 10px;
}

.item-details-cart-count:disabled {
  background-color: #6c757d !important;
}

.addons-title-main {
  padding: 10px 15px;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #ededed;
  margin-bottom: 1rem;
}

.addons-title-a {
  font-size: 14px;
}

.addons-title-b {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}

.addon-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 1rem;
}

.addon-select input {
  padding-top: 5px;
  transform: scale(1.3);
  margin-top: -3px;
  vertical-align: middle;
  margin-right: 10px;
}

.addon-disabled {
  font-weight: 400;
  color: #cccccc;
}

.addon-select input:disabled + label {
  color: #cccccc;
}

.addon-select label {
  margin-bottom: 0;
}

.item-table-item-details {
  display: flex;
  width: 100%;
}

.item-table-item-details-item-name {
  width: 59%;
  font-size: 16px;
  font-weight: 500;
}

.item-table-item-details-item-count {
  width: 16%;
}

.item-table-item-details-item-price {
  width: 25%;
  text-align: right;
}

.addons-display-cart,
.cart-items-checkout-btn,
.portion-display-cart {
  padding-left: 16%;
}

.portion-display-cart {
  margin-bottom: 10px;
}

.addons-display-cart-title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
}

.addons-display-cart-addon-item {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 1px;
}

.item-table-item-details-main {
  margin-bottom: 2rem;
}

.cart-continue-shopping {
  text-align: center;
  margin-top: 0.5rem;
}

.cart-continue-shopping a {
  font-size: 14px;
}

.cart-btn-full-card-size {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.cart-btn-full-card-size:focus {
  outline: none;
}

.cart-continue-login {
  text-align: center;
  margin-top: 0.5rem;
}

.cart-continue-login a {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.cart-continue-login a:hover {
  text-decoration: underline;
}

.pre_order_wrapper .item-details-cart-count {
  width: 26px;
  height: 26px;
  border-radius: 6px;
  outline: 0;
  border: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.pre_order_wrapper .menu-item-couter {
  width: auto;
}

.pre-order-title-main {
  padding: 10px 13px;
  background-color: #ededed;
  border-radius: 5px;
  display: flex;
  gap: 8px;
}

.pre-order-title-main i {
  color: #0075ff;
  margin-top: 2px;
  display: block;
}

@media (max-width: 768px) {
  .cart-main {
    top: 107px;
  }
  .finalized-order-info {
    margin-left: 0rem;
  }
}

@media (max-width: 520px) {
  .cart-mobile {
    display: block;
  }

  .cart-sec .dec-btn,
  .cart-sec .inc-btn {
    padding: 4px 6px 2px 6px;
    font-size: 12px;
  }

  .cart-sec .subtotal {
    margin-top: 5px;
  }

  .cart-main {
    top: 77px;
    width: 98%;
    right: 1%;
    min-width: auto;
    max-height: 90vh;
    overflow: auto;
    box-shadow: 9px 8px 13px 11px rgba(0, 0, 0, 0.1);
  }

  .login-register-popup-main .modal-open .modal {
    padding-right: 0;
  }

  /* Main Cart */
  /* .cart-sec .item-table tr {
    display: grid;
    background-color: #f7f7f7;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .cart-sec .item-table tr td:nth-child(1) {
    width: auto;
    grid-row: 1/2;
    grid-column: 1/3;
    padding: 0;
  }

  .cart-sec .item-table tr td:nth-child(2) {
    grid-row: 1/2;
    grid-column: 2/3;
    min-width: auto;
    padding: 0;
  }

  .cart-sec .item-table tr td:nth-child(3) {
    min-width: auto;
    grid-row: 1;
    grid-column: 3/3;
    padding: 0;
  }

  .cart-sec .item-table tr td {
    padding: 0;
  } */

  .cart-sec .item-table tr td:nth-child(1) {
    font-size: 12px;
    width: 10%;
  }

  .cart-sec .item-table tr td:nth-child(2) {
    font-size: 12px;
    width: 70%;
  }

  .cart-sec .item-table tr td:nth-child(3) {
    font-size: 12px;
    padding-right: 0;
    width: 20%;
    min-width: 94px;
  }

  .order-qty {
    padding: 4px 4px;
  }

  .cart-order-total {
    font-size: 13px;
  }

  .cart-sec .cart-heading {
    font-size: 14px;
  }

  .match-min-order-checkbox {
    text-align: left;
    display: flex;
  }

  .match-min-order-checkbox input {
    margin-top: 3px;
  }

  .radio-tile-group .input-container {
    height: 4rem;
    width: 4rem;
  }

  .radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: column;
  }

  .radio-icon {
    margin-right: 0;
  }

  .radio-tile-group .input-container .radio-tile-label {
    font-size: 10px;
    margin-top: 5px;
  }

  .remove-cart-item {
    margin-top: 5px;
  }

  .cart-sec .checkout {
    width: 75%;
  }

  .cart-sec .item-table tr {
    background-color: #f7f7f7;
    margin-bottom: 10px;
    display: block;
    padding: 7px;
    border-radius: 5px;
  }

  .cart-sec .item-table tr td {
    padding: 5px 4px !important;
  }

  .nav-go-to-menu-main {
    align-items: center;
  }

  .logged-user {
    width: 25px;
    height: 25px;
  }

  .cus-con-for-all.cart-main {
    border-radius: 5px;
  }
  .finalized-order-info {
    margin-left: 0;
    flex-direction: column;
    font-size: 12px;
  }
  .finalized-order-type i,
  .finalized-order-time i {
    width: 18px;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .delivery-pickup-dine-select {
    font-size: 12px;
  }
  .item-details-cart-count {
    min-width: 32px;
  }

  .add-to-cart-btn {
    width: 67% !important;
  }

  .delivery-pickup-dine-select .dropdown-menu {
    min-width: 10rem;
  }
}

@media (max-width: 420px) {
  h5.cart-heading {
    font-size: 16px;
  }

  .add-to-cart-btn {
    width: 66%;
  }
  .item-table-item-details-item-count {
    width: 18%;
  }
  .addons-display-cart,
  .cart-items-checkout-btn,
  .portion-display-cart {
    padding-left: 18%;
  }
  .item-table-item-details-item-name {
    font-size: 15px;
  }
  .item-table-item-details-item-price {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .navbar-rest-name h3 {
    font-size: 20px !important;
  }
}

@-moz-document url-prefix() {
  .shopping-cart .fa-shopping-cart {
    bottom: auto;
  }

  .shopping-cart .cart-badge {
    top: 0px;
  }
}

@media (max-width: 340px) {
  .finalized-order-info {
    display: none;
  }
  .item-table-item-details-main {
    overflow: auto;
  }
  .item-table-item-details-item-count {
    min-width: 56px;
  }
  .item-table-item-details-item-name {
    min-width: 140px;
  }
  .item-table-item-details-item-price {
    min-width: 75px;
  }
}

@media (max-width: 576px) {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .menu-item-image {
    border-radius: 0;
  }

  .menu-item-image img {
    object-fit: contain;
    width: 100%;
    max-height: 270px;
  }

  .modal-dialog-centered {
    align-items: flex-start;
  }
}
