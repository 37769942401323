:root {
  --main-background-color: #2d595b;
}

.login-sign-up-modal .heading {
  text-align: center;
  font-weight: 600;
  margin: 0 0 20px 0;
}

.login-sign-up-modal label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.login-sign-up-modal .form-control:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #000;
}

.login-sign-up-modal .btn {
  width: 100%;
  background: var(--main-background-color, #2d595b);
  border: 0;
  color: #fff;
  padding: 7px;
  font-size: 14px;
  font-weight: 500;
}

.login-sign-up-modal .btn:focus {
  outline: 0;
  box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-width: 1px;
  border-style: solid;
  background-image: none;
  background-color: #ededed;
}

.login-register-popup-main .modal-header {
  padding: 15px 0;
}

.login-register-popup-main .modal-body {
  padding: 0 0 15px 0;
}

.spritzer-note {
  color: #333333;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 1rem;
  line-height: 1.4;
}

.login-reg-popup {
  padding: 2px 15px 15px 15px;
}

.login-reg-popup .modal-header {
  border-bottom: 1px solid #dee2e6;
  align-items: baseline;
}

.login-notify {
  font-size: 14px;
  color: red;
  margin-bottom: 0;
  margin-top: 15px;
  text-align: center;
}

.login-notify i {
  margin-right: 6px;
  vertical-align: middle;
}

.login-notify-success {
  font-size: 14px;
  color: #009688;
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
}

.login-notify-success i {
  margin-right: 6px;
  vertical-align: middle;
}

.resend-btn {
  background: #2196f3;
  color: #fff;
  border: 0;
  border-radius: 3;
  margin: auto;
  padding: 3px 10px;
  display: block;
  margin-top: 10;
  margin-bottom: 10;
}

.login-reg-form {
  margin-top: 1rem;
}

.login-reg-form label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.login-or-reg-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.login-or-reg-text-btn {
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}

.login-or-reg-text-btn:hover {
  color: #006dee;
}

.login-or-reg-button {
  flex-basis: 50%;
}

.login-reg-popup .close {
  opacity: 1;
  outline: none;
}

.login-register-popup-footer {
  text-align: center;
  color: #333333;
  margin-top: 1rem;
}

.login-register-popup-footer h6 {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.login-register-popup-footer h6 span {
  font-weight: 700;
}

.login-register-popup-footer h6 a {
  color: #333333;
}

.login-register-popup-footer a {
  font-size: 13px;
}

.login-reg-form .form-group {
  margin-bottom: 0.5rem;
}

.forgot-password-btn {
  text-align: center;
  margin-top: 2rem;
}

.forgot-password-btn p {
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  margin: 0;
  font-weight: 500;
}

.forgot-password-btn p:hover {
  color: #006dee;
}

.forgot-password-main .close {
  padding-top: 0.7rem;
}

.login-or-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.login-or-text span {
  flex-grow: 1;
}

.login-or-text span:first-child {
  background-color: #dee2e6;
  height: 1px;
}

.login-or-text span:nth-child(2) {
  flex-grow: 0;
  margin: 0 10px;
  font-size: 14px;
}

.login-or-text span:last-child {
  background-color: #dee2e6;
  height: 1px;
}
