:root {
  --main-background-color: #2d595b;
}

/* jumbo */
.jumbo-sec {
  height: 500px;
  overflow: hidden;
  border-radius: 0;
}

.jumbo-sec .jumbo-div {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.jumbo-sec h1 {
  color: #ffffff;
  font-size: 60px;
  line-height: 65px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 900;
}

.jumbo-sec h5 {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 38px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 0;
}

.support-hash-tag {
  position: absolute;
  bottom: 15px;
  width: 200px;
  right: 15px;
  background: #fff;
  border-radius: 25px;
}

.jumbo-sec .background {
  height: 500px;
  object-fit: cover;
  width: 100vw;
  filter: brightness(50%);
}

.form-control {
  color: #222222;
  background-color: #ededed;
  border: none;
  border-radius: 0.25rem;
}

.modal-header .close {
  opacity: 1;
  outline: none;
}

.modal-header .close span:hover {
  color: red;
}

.modal-content {
  padding: 2px 15px 15px 15px;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  padding: 10px 0;
}

/* Order type radio */
.radio-tile-group {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
}

.radio-tile-group .input-container {
  position: relative;
  height: 2.6rem;
  width: 6.5rem;
  margin: 0.5rem;
  transition: 0.2s;
}

.radio-tile-group .input-container .order-radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
  display: -webkit-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #d4d4d4;
  border-radius: 5px;
  -webkit-transition: -webkit-transform 300ms ease;
  transition: -webkit-transform 300ms ease;
  transition: transform 300ms ease;
  transition: transform 300ms ease, -webkit-transform 300ms ease;
}

.radio-tile-group .input-container .radio-icon svg {
  fill: #333333;
  width: 1.4rem;
  height: 1.4rem;
}

.radio-icon {
  margin-right: 7px;
}

.radio-tile-group .input-container:hover {
  background-color: #e9ecef;
  border-radius: 5px;
}

.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 0;
}

.radio-tile-group .input-container .order-radio-button:checked + .radio-tile {
  background-color: var(--main-background-color, #2d595b);
  border: 2px solid var(--main-background-color, #2d595b);
  color: white;
  -webkit-transform: scale(1.1, 1.1);
  transform: scale(1.1, 1.1);
}

.radio-tile-group
  .input-container
  .order-radio-button:checked
  + .radio-tile
  .radio-icon
  svg {
  fill: white;
}

.radio-tile-group
  .input-container
  .order-radio-button:checked
  + .radio-tile
  .radio-tile-label {
  color: white;
}

.order-time-change {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.order-time-change-inner i {
  margin-right: 8px;
  padding-top: 4px;
}

.order-time-change-inner i.fas.fa-calendar-alt.pr-2 {
  margin-right: 2px;
  padding-top: 4px;
}

.order-time-change-inner {
  font-size: 14px;
  cursor: pointer;
}

.order-time-change-inner:hover {
  color: #006dee;
}

.order-time-change-inner::after {
  margin-left: 0.4em;
  margin-top: 8px;
}

.order-type-selection {
  margin-bottom: 1rem;
}

.order-time-change-inner {
  display: flex;
  transition: 0.2s;
}

.close {
  outline: 0 !important;
}

select:focus {
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: #dfdfdf;
}

.user-display-btn {
  padding: 8px 15px;
  background-color: hsla(0, 0%, 51%, 0.41);
  border-radius: 5px;
}

.user-display-btn span {
  font-weight: 500;
}

.user-display-btn .dropdown-toggle:after {
  margin-left: 0.6em;
  vertical-align: 0;
}

.nav-right-btn-block {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-mobile-view {
  display: none;
}

@media (max-width: 480px) {
  .jumbo-sec h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .jumbo-sec h5 {
    font-size: 24px;
    line-height: 35px;
  }
}

@media (min-width: 481px) and (max-width: 992px) {
  .jumbo-sec h1 {
    font-size: 50px;
    line-height: 55px;
  }

  .jumbo-sec h5 {
    font-size: 30px;
    line-height: 35px;
  }
}

/* common */
.heading {
  font-size: 26px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bold;
  margin: 60px 0 10px 0;
}

.para {
  text-align: center;
  margin-bottom: 25px;
}

.apply-venues-sec .apply-now {
  display: block;
  margin: auto;
  width: 245px;
  margin-top: 30px;
  background: #ff7068;
  color: #ffffff !important;
  border-radius: 5px;
  padding: 12px 25px 12px 25px !important;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 25px !important;
  text-align: center;
}

.apply-venues-sec .apply-now:hover {
  background: #df352c;
  text-decoration: none;
}

.divider {
  margin-bottom: 0;
}

/* area cards */
.card-sec .card {
  position: relative;
  display: inline-block;
  width: 31.3%;
  border-radius: 3px;
  text-align: center;
  border: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin: 1%;
}

.card-sec .card-body {
  padding: 0;
  border-radius: 5px !important;
}

.card-sec h2 {
  font-size: 20px;
  line-height: 24px;
  color: #2e3333;
  font-weight: 700;
  margin-bottom: 22px;
}

.card-sec .location-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(80%);
  border-radius: 5px !important;
}

.card-sec .card-body:hover .location-img {
  filter: brightness(70%);
}

.card-sec .location-coming-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  border-radius: 5px !important;
}

.card-sec .location-coming-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(50%);
  border-radius: 5px !important;
}

.card-sec .venue-card-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.card-sec .card-title {
  font-size: 25px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 2px;
}

.card-sec .card-para {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 0;
}

.card-sec .second-card {
  margin: 0 2% 2% 2%;
}

.nav-go-to-menu {
  color: inherit;
  transition: 0.2s;
  font-size: 0.95rem;
}

.nav-go-to-menu:hover {
  color: inherit;
}

.nav-go-to-menu-main {
  display: flex;
}

.nav-go-to-menu-inner {
  margin-right: 2rem;
}

.nav-go-to-menu-inner:last-child {
  margin-right: 0;
}

.nav-go-to-menu {
  margin-bottom: 0;
  cursor: pointer;
}

.res-closed-msg {
  text-align: center;
  margin: 0.5rem 0;
  background: #fff4e8;
  padding: 1rem;
}

.res-closed-msg i {
  font-size: 32px;
  margin-bottom: 1rem;
  color: #6495ed;
}

.schedule-text {
  text-align: center;
  margin-bottom: 1rem;
}

.res-closed-msg h6 {
  font-size: 15px;
}

.filter-bar::-webkit-scrollbar {
  height: 10px;
  /* background-color: #F5F5F5; */
}

.filter-bar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
}

.filter-bar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.filter-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
  background-color: #f7f7f7;
  border-radius: 6px;
}

@media (max-width: 480px) {
  .card-sec .card {
    width: 100%;
  }

  .card-sec .card-body {
    padding-bottom: 0;
  }

  /* .card-sec .card:nth-child(4),
  .card-sec .card:nth-child(5),
  .card-sec .card:nth-child(6) {
    display: none;
  } */
}

@media (min-width: 481px) and (max-width: 768px) {
  .card-sec .card {
    width: 48%;
    margin: 0.75%;
  }

  .card-sec .card-body {
    padding-bottom: 0;
  }

  .card-sec .card-title {
    font-size: 18px;
    line-height: 21px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .card-sec .card-title {
    font-size: 18px;
    line-height: 21px;
  }
}

/* ends of how-spritzer-works section */

/* rest card */
.rest-card {
  display: block;
  float: left;
  position: relative;
  width: 31%;
  margin: 2% 1.1%;
  border-radius: 5px;
  height: 385px;
}

.rest-card .img-div {
  position: relative;
  height: 170px;
  overflow: hidden;
}

.rest-card .img-div img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.rest-card .img-div img:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.rest-card .img-div p {
  position: absolute;
  color: #2f2f2f;
  padding: 1px 10px 1px 10px;
  border-radius: 25px;
  font-size: 13px;
  top: 10px;
  right: 10px;
  background: #dedede;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}

.rest-card .name {
  font-weight: 600;
  margin: 10px;
  height: 24px;
  font-size: 18px;
  overflow: hidden;
}

.rest-card .cusines {
  margin: 10px;
  font-size: 14px;
  color: #717171;
  height: 65px;
  overflow: hidden;
  text-align: left;
}

.rest-card .payment-method {
  display: inline-block;
  background: #e2e2e2;
  color: #1f1f1f;
  padding: 3px 10px 2px 10px;
  border-radius: 23px;
  margin: 5px 0 10px 10px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.rest-card .min-spend {
  display: inline-block;
  background: #1f1f1f;
  color: #e2e2e2;
  padding: 3px 10px 2px 10px;
  border-radius: 23px;
  margin: 5px 0 5px 10px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.user-account-info-popup {
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  outline: none;
  overflow: hidden;
  animation: gb__a 0.2s;
  -webkit-user-select: text;
  width: 354px;
  padding: 2rem 1rem 1rem 1rem;
  text-align: center;
  border-radius: 5px;
}

.user-account-info-popup .dropdown-item {
  text-align: left;
  font-size: 0.9rem;
}

.user-account-info-popup hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.display-user-name-nav {
  font-size: 18px;
  font-weight: 500;
}

.display-user-email-nav i {
  margin-left: 5px;
  font-size: 12px;
  color: #2979ff;
}

.display-user-email-nav {
  margin-top: 5px;
  color: #555555;
  margin-bottom: 3px;
  font-size: 0.9rem;
}

.nav-user-active-state {
  color: #2979ff;
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.7rem;
  cursor: default;
}

.nav-user-info {
  pointer-events: none;
}

.category-wrapper {
  position: relative;
}

.category-scool-element {
  position: absolute;
  display: block;
  top: -90px;
}

@media (max-width: 768px) {
  .nav-mobile-view {
    display: block;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: hsla(0, 0%, 51%, 0.41);
  }

  .nav-go-to-menu.dropdown-item {
    color: #222222;
    padding: 0.6rem 1.5rem;
    border-bottom: 1px solid #eaeaea;
  }

  .nav-go-to-menu.dropdown-item:last-child{
    border-bottom: none;
  }

  .nav-go-to-menu.dropdown-item i {
    font-size: 0.9rem;
    color: #495057;
    vertical-align: middle;
    margin-right:7px;
    width: 15px;
    text-align: center;
  }

  .nav-mobile-view .dropdown-item i {
    font-size: 0.9rem;
    color: #495057;
    vertical-align: middle;
    margin-right:7px;
    width: 15px;
    text-align: center;
  }
  .nav-mobile-view i {
    font-size: 27px;
    vertical-align: bottom;
  }
  .login-reg-popup {
    padding: 6px 15px 15px 15px;
  }
}

@media (max-width: 520px) {
  .navbar {
    flex-wrap: nowrap;
  }

  .user-name {
    display: none;
  }

  .displayUserNameMobile {
    padding: 5px 0 10px 22px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #dee2e6;
  }

  .shopping-cart {
    top: 1px;
    right: -7px;
  }

  .navbar > .container-fluid {
    padding: 0;
    position: relative;
  }
  .user-display-btn {
    position: unset;
  }
  .user-account-info-popup {
    left: unset;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .rest-card {
    display: block;
    width: 92%;
    height: 340px;
    margin: 5px 4%;
  }

  .rest-card .img-div {
    height: 160px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (min-width: 481px) and (max-width: 650px) {
  .rest-card {
    width: 45%;
    height: 320px;
    margin: 2% 2.5%;
  }

  .rest-card .img-div {
    height: 120px;
  }

  .rest-card .name {
    height: 40px;
  }

  .rest-card .cusines {
    height: 85px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (min-width: 651px) and (max-width: 768px) {
  .rest-card {
    width: 45%;
    height: 350px;
    margin: 2% 2.5%;
  }

  .rest-card .img-div {
    height: 140px;
  }

  .rest-card .name {
    height: 48px;
  }

  .rest-card .cusines {
    height: 85px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .rest-card {
    width: 47%;
    height: 330px;
    margin: 2% 1.5%;
  }

  .rest-card .img-div {
    height: 140px;
  }

  .rest-card .cusines {
    height: 85px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

/* thanking */
.thanking-letter-div {
  max-width: 1000px;
  margin: auto;
}

.author {
  font-family: "Dancing Script", cursive;
  text-align: center;
  margin-bottom: 0;
  font-weight: 500;
}

.founder {
  text-align: center;
  margin-bottom: 0;
  color: #737373;
  font-size: 15px;
}
