.centered-button {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error-code {
  color: #fff;
  text-align: center;
  font-size: 90px;
  font-weight: 900;
  letter-spacing: 8;
}
.error-description {
  color: #fff;
  text-align: center;
  font-size: 30px;
}
.main-container {
  display: flex;
  justify-content: center;
}
.reload-button {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  border: 1.5px solid;
  cursor: pointer;
}
