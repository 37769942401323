.order-status-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.order-status-id {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: #e4e6e7;
  border-radius: 6px;
  padding: 1rem 1rem;
}

.order-status-id h6 {
  font-size: 1em;
  font-weight: 300;
  margin-top: 1.1rem;
  margin-bottom: 2px;
}

.order-status-id h5 {
  font-size: 1.08em;
  font-weight: 600;
}

.order-status-title {
  font-size: 1.1em;
}

.order-status-resturant-name {
  font-size: 2em;
}

.order-status-timeline {
  margin: 2.5rem 2.5rem;
}

.status {
  font-size: 1em;
  font-weight: 600;
  margin-left: 0.5rem;
}

.date-time {
  font-size: 0.9em;
  font-weight: 400;
  margin-left: 0.5rem;
}

/* Status time line */
.time-line {
  display: flex;
  flex-direction: row;
}

.icon-line {
  display: flex;
  flex-direction: column;
}

.detail-line {
  display: flex;
  flex-direction: column;
}

.status-icon {
  border-left: 2px solid #d4d4d4;
  height: 5.5em;
}

.detail-box {
  height: 5.5em;
  text-align: left;
}

.fa-check-circle {
  color: #348bf0;
}

.fa-check-circle::before {
  display: inline-block;
  margin: 0 0 2.5em -0.5em;
  background-color: white;
}

.error-msg h5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 300;
}

@media (max-width: 975px) {
  .order-status-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .order-status-id {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: #e4e6e7;
    border-radius: 6px;
    padding: 1rem 1rem;
  }

  .order-status-id h6 {
    font-size: 1em;
    font-weight: 300;
    margin-top: 1.1rem;
    margin-bottom: 2px;
  }

  .order-status-id h5 {
    font-size: 1.08em;
    font-weight: 500;
  }

  .order-status-title {
    font-size: 1.1em;
  }

  .order-status-resturant-name {
    font-size: 1.8em;
  }

  .order-status-timeline {
    margin: 2.5rem 3rem;
  }

  .status {
    font-size: 1em;
    font-weight: 600;
  }

  .date-time {
    font-size: 0.9em;
    font-weight: 400;
  }

  /* Status time line */
  .time-line {
    display: flex;
    flex-direction: row;
  }

  .icon-line {
    display: flex;
    flex-direction: column;
  }

  .detail-line {
    display: flex;
    flex-direction: column;
  }

  .status-icon {
    border-left: 2px solid #d4d4d4;
    height: 5em;
  }

  .detail-box {
    height: 5em;
    text-align: left;
  }

  .fa-check-circle {
    color: #348bf0;
  }

  .fa-check-circle::before {
    display: inline-block;
    margin: 0 0 2.5em -0.5em;
    background-color: white;
  }

  .error-msg h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 300;
  }
}

@media (max-width: 575px) {
  .order-status-container {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .order-status-id {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: #e4e6e7;
    border-radius: 6px;
    padding: 1rem 1rem;
  }

  .order-status-id h6 {
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 1.1rem;
    margin-bottom: 2px;
  }

  .order-status-id h5 {
    font-size: 1em;
    font-weight: 500;
  }

  .order-status-title {
    font-size: 0.9em;
    margin-top: 0.8rem;
  }

  .order-status-resturant-name {
    font-size: 1.4em;
    font-weight: 500;
  }

  .order-status-timeline {
    text-align: left;
    margin: 2rem 3rem;
  }

  .status {
    font-size: 0.9em;
    font-weight: 500;
  }

  .date-time {
    font-size: 0.8em;
    font-weight: 300;
  }

  /* Status time line */
  .time-line {
    display: flex;
    flex-direction: row;
  }

  .icon-line {
    display: flex;
    flex-direction: column;
  }

  .detail-line {
    display: flex;
    flex-direction: column;
  }

  .status-icon {
    border-left: 2px solid #d4d4d4;
    height: 5em;
  }

  .detail-box {
    height: 5em;
    text-align: left;
  }

  .fa-check-circle {
    color: #348bf0;
  }

  .fa-check-circle::before {
    display: inline-block;
    margin: 0 0 2.5em -0.5em;
    background-color: white;
  }

  .error-msg h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
    font-weight: 300;
  }
}
