.footer-sec {
  padding: 30px 40px 0 40px;
}

.col-social {
  width: 60%;
}

.col-rest-about {
  width: 40%;
  text-align: right;
}

.rest-logo-footer {
  float: right;
  width: auto;
  max-width: 130px;
  height: 35px;
  object-fit: contain;
  object-position: right;
}

.footer-shop-name {
  text-align: right;
  padding-top: 8px;
}

.social-dev {
  padding-top: 10px;
}

.social-icon {
  width: 25px;
  margin-right: 10px;
}

.shop-copy-right {
  text-align: center;
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 5px;
}

.footer-restaurant.info {
  margin-top: 2rem;
}

@media (max-width: 480px) {
  .footer-sec {
    padding: 30px 20px 0 20px;
  }

  .col-social {
    width: 100%;
    order: 2;
  }

  .col-rest-about {
    width: 100%;
    order: 1;
    text-align: center;
  }

  .rest-logo-footer {
    float: left;
    margin-bottom: 10px;
  }

  .footer-shop-name {
    text-align: left;
    padding-bottom: 5px;
  }

  .social-icon {
    width: 20px;
  }

  .shop-copy-right {
    margin-top: 20px;
    margin-bottom: 8px;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .footer-sec {
    padding: 30px 17px 0 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .footer-sec {
    padding: 30px 22px 0 22px;
  }
}

@media (min-width: 1280px) {
  .footer-sec .cus-con-for-all {
    padding: 0 2.5%;
  }
}
