.schedule-date {
  display: block;
  width: 80%;
  margin: auto;
  cursor: pointer;
}

.schedule-date:focus {
  border: 1px solid #000 !important;
  box-shadow: none;
}

.schedule-date option:hover {
  border: none !important;
}

.schedule-btn {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: .25rem;
  transition: 0.3s;
  background-color: #2D595B;
}

.schedule-btn:hover {
  filter: brightness(1.1);
}

.schedule-btn:focus {
  outline: 0;
}

.cancel-schedule-btn {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: .25rem;
  transition: 0.3s;
  background-color: #6c757d;
  margin-top: 0.5rem;
}

.cancel-schedule-btn:hover {
  filter: brightness(1.1);
}

.cancel-schedule-btn:focus {
  outline: 0;
}