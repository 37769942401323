@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Passero+One&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cus-con-for-all {
  max-width: 1680px;
  margin: auto;
}

.notification-container--top-right {
  top: 68px !important;
}

:root {
  --main-background-color: #2d595b;
}

.login-sign-up-modal .heading {
  text-align: center;
  font-weight: 600;
  margin: 0 0 20px 0;
}

.login-sign-up-modal label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.login-sign-up-modal .form-control:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #000;
}

.login-sign-up-modal .btn {
  width: 100%;
  background: #2d595b;
  background: var(--main-background-color, #2d595b);
  border: 0;
  color: #fff;
  padding: 7px;
  font-size: 14px;
  font-weight: 500;
}

.login-sign-up-modal .btn:focus {
  outline: 0;
  box-shadow: none;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  border-width: 1px;
  border-style: solid;
  background-image: none;
  background-color: #ededed;
}

.login-register-popup-main .modal-header {
  padding: 15px 0;
}

.login-register-popup-main .modal-body {
  padding: 0 0 15px 0;
}

.spritzer-note {
  color: #333333;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 1rem;
  line-height: 1.4;
}

.login-reg-popup {
  padding: 2px 15px 15px 15px;
}

.login-reg-popup .modal-header {
  border-bottom: 1px solid #dee2e6;
  align-items: baseline;
}

.login-notify {
  font-size: 14px;
  color: red;
  margin-bottom: 0;
  margin-top: 15px;
  text-align: center;
}

.login-notify i {
  margin-right: 6px;
  vertical-align: middle;
}

.login-notify-success {
  font-size: 14px;
  color: #009688;
  margin-top: 15px;
  margin-bottom: 0;
  text-align: center;
}

.login-notify-success i {
  margin-right: 6px;
  vertical-align: middle;
}

.resend-btn {
  background: #2196f3;
  color: #fff;
  border: 0;
  border-radius: 3;
  margin: auto;
  padding: 3px 10px;
  display: block;
  margin-top: 10;
  margin-bottom: 10;
}

.login-reg-form {
  margin-top: 1rem;
}

.login-reg-form label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.login-or-reg-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.login-or-reg-text-btn {
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
}

.login-or-reg-text-btn:hover {
  color: #006dee;
}

.login-or-reg-button {
  flex-basis: 50%;
}

.login-reg-popup .close {
  opacity: 1;
  outline: none;
}

.login-register-popup-footer {
  text-align: center;
  color: #333333;
  margin-top: 1rem;
}

.login-register-popup-footer h6 {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
}

.login-register-popup-footer h6 span {
  font-weight: 700;
}

.login-register-popup-footer h6 a {
  color: #333333;
}

.login-register-popup-footer a {
  font-size: 13px;
}

.login-reg-form .form-group {
  margin-bottom: 0.5rem;
}

.forgot-password-btn {
  text-align: center;
  margin-top: 2rem;
}

.forgot-password-btn p {
  font-size: 13px;
  text-decoration: underline;
  cursor: pointer;
  display: inline;
  margin: 0;
  font-weight: 500;
}

.forgot-password-btn p:hover {
  color: #006dee;
}

.forgot-password-main .close {
  padding-top: 0.7rem;
}

.login-or-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0;
}

.login-or-text span {
  flex-grow: 1;
}

.login-or-text span:first-child {
  background-color: #dee2e6;
  height: 1px;
}

.login-or-text span:nth-child(2) {
  flex-grow: 0;
  margin: 0 10px;
  font-size: 14px;
}

.login-or-text span:last-child {
  background-color: #dee2e6;
  height: 1px;
}

.schedule-date {
  display: block;
  width: 80%;
  margin: auto;
  cursor: pointer;
}

.schedule-date:focus {
  border: 1px solid #000 !important;
  box-shadow: none;
}

.schedule-date option:hover {
  border: none !important;
}

.schedule-btn {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: .25rem;
  transition: 0.3s;
  background-color: #2D595B;
}

.schedule-btn:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.schedule-btn:focus {
  outline: 0;
}

.cancel-schedule-btn {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: .25rem;
  transition: 0.3s;
  background-color: #6c757d;
  margin-top: 0.5rem;
}

.cancel-schedule-btn:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.cancel-schedule-btn:focus {
  outline: 0;
}
:root {
  --main-background-color: #2d595b;
}

/* jumbo */
.jumbo-sec {
  height: 500px;
  overflow: hidden;
  border-radius: 0;
}

.jumbo-sec .jumbo-div {
  position: absolute;
  top: 46%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.jumbo-sec h1 {
  color: #ffffff;
  font-size: 60px;
  line-height: 65px;
  letter-spacing: 2px;
  text-align: center;
  font-weight: 900;
}

.jumbo-sec h5 {
  color: #ffffff;
  margin-bottom: 30px;
  font-size: 38px;
  line-height: 50px;
  text-align: center;
  margin-bottom: 0;
}

.support-hash-tag {
  position: absolute;
  bottom: 15px;
  width: 200px;
  right: 15px;
  background: #fff;
  border-radius: 25px;
}

.jumbo-sec .background {
  height: 500px;
  object-fit: cover;
  width: 100vw;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
}

.form-control {
  color: #222222;
  background-color: #ededed;
  border: none;
  border-radius: 0.25rem;
}

.modal-header .close {
  opacity: 1;
  outline: none;
}

.modal-header .close span:hover {
  color: red;
}

.modal-content {
  padding: 2px 15px 15px 15px;
}

.modal-header {
  border-bottom: 1px solid #dee2e6;
  padding: 10px 0;
}

/* Order type radio */
.radio-tile-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.radio-tile-group .input-container {
  position: relative;
  height: 2.6rem;
  width: 6.5rem;
  margin: 0.5rem;
  transition: 0.2s;
}

.radio-tile-group .input-container .order-radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid #d4d4d4;
  border-radius: 5px;
  transition: transform 300ms ease;
}

.radio-tile-group .input-container .radio-icon svg {
  fill: #333333;
  width: 1.4rem;
  height: 1.4rem;
}

.radio-icon {
  margin-right: 7px;
}

.radio-tile-group .input-container:hover {
  background-color: #e9ecef;
  border-radius: 5px;
}

.radio-tile-group .input-container .radio-tile-label {
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333333;
  margin-bottom: 0;
}

.radio-tile-group .input-container .order-radio-button:checked + .radio-tile {
  background-color: #2d595b;
  background-color: var(--main-background-color, #2d595b);
  border: 2px solid #2d595b;
  border: 2px solid var(--main-background-color, #2d595b);
  color: white;
  transform: scale(1.1, 1.1);
}

.radio-tile-group
  .input-container
  .order-radio-button:checked
  + .radio-tile
  .radio-icon
  svg {
  fill: white;
}

.radio-tile-group
  .input-container
  .order-radio-button:checked
  + .radio-tile
  .radio-tile-label {
  color: white;
}

.order-time-change {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.order-time-change-inner i {
  margin-right: 8px;
  padding-top: 4px;
}

.order-time-change-inner i.fas.fa-calendar-alt.pr-2 {
  margin-right: 2px;
  padding-top: 4px;
}

.order-time-change-inner {
  font-size: 14px;
  cursor: pointer;
}

.order-time-change-inner:hover {
  color: #006dee;
}

.order-time-change-inner::after {
  margin-left: 0.4em;
  margin-top: 8px;
}

.order-type-selection {
  margin-bottom: 1rem;
}

.order-time-change-inner {
  display: flex;
  transition: 0.2s;
}

.close {
  outline: 0 !important;
}

select:focus {
  outline: -webkit-focus-ring-color auto 1px;
  outline-color: #dfdfdf;
}

.user-display-btn {
  padding: 8px 15px;
  background-color: hsla(0, 0%, 51%, 0.41);
  border-radius: 5px;
}

.user-display-btn span {
  font-weight: 500;
}

.user-display-btn .dropdown-toggle:after {
  margin-left: 0.6em;
  vertical-align: 0;
}

.nav-right-btn-block {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.nav-mobile-view {
  display: none;
}

@media (max-width: 480px) {
  .jumbo-sec h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .jumbo-sec h5 {
    font-size: 24px;
    line-height: 35px;
  }
}

@media (min-width: 481px) and (max-width: 992px) {
  .jumbo-sec h1 {
    font-size: 50px;
    line-height: 55px;
  }

  .jumbo-sec h5 {
    font-size: 30px;
    line-height: 35px;
  }
}

/* common */
.heading {
  font-size: 26px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: bold;
  margin: 60px 0 10px 0;
}

.para {
  text-align: center;
  margin-bottom: 25px;
}

.apply-venues-sec .apply-now {
  display: block;
  margin: auto;
  width: 245px;
  margin-top: 30px;
  background: #ff7068;
  color: #ffffff !important;
  border-radius: 5px;
  padding: 12px 25px 12px 25px !important;
  font-weight: 400;
  font-size: 18px !important;
  line-height: 25px !important;
  text-align: center;
}

.apply-venues-sec .apply-now:hover {
  background: #df352c;
  text-decoration: none;
}

.divider {
  margin-bottom: 0;
}

/* area cards */
.card-sec .card {
  position: relative;
  display: inline-block;
  width: 31.3%;
  border-radius: 3px;
  text-align: center;
  border: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  margin: 1%;
}

.card-sec .card-body {
  padding: 0;
  border-radius: 5px !important;
}

.card-sec h2 {
  font-size: 20px;
  line-height: 24px;
  color: #2e3333;
  font-weight: 700;
  margin-bottom: 22px;
}

.card-sec .location-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: brightness(80%);
          filter: brightness(80%);
  border-radius: 5px !important;
}

.card-sec .card-body:hover .location-img {
  -webkit-filter: brightness(70%);
          filter: brightness(70%);
}

.card-sec .location-coming-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  border-radius: 5px !important;
}

.card-sec .location-coming-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-filter: brightness(50%);
          filter: brightness(50%);
  border-radius: 5px !important;
}

.card-sec .venue-card-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}

.card-sec .card-title {
  font-size: 25px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 2px;
}

.card-sec .card-para {
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  margin-bottom: 0;
}

.card-sec .second-card {
  margin: 0 2% 2% 2%;
}

.nav-go-to-menu {
  color: inherit;
  transition: 0.2s;
  font-size: 0.95rem;
}

.nav-go-to-menu:hover {
  color: inherit;
}

.nav-go-to-menu-main {
  display: flex;
}

.nav-go-to-menu-inner {
  margin-right: 2rem;
}

.nav-go-to-menu-inner:last-child {
  margin-right: 0;
}

.nav-go-to-menu {
  margin-bottom: 0;
  cursor: pointer;
}

.res-closed-msg {
  text-align: center;
  margin: 0.5rem 0;
  background: #fff4e8;
  padding: 1rem;
}

.res-closed-msg i {
  font-size: 32px;
  margin-bottom: 1rem;
  color: #6495ed;
}

.schedule-text {
  text-align: center;
  margin-bottom: 1rem;
}

.res-closed-msg h6 {
  font-size: 15px;
}

.filter-bar::-webkit-scrollbar {
  height: 10px;
  /* background-color: #F5F5F5; */
}

.filter-bar::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 6px;
}

.filter-bar::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}

.filter-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 10%);
  background-color: #f7f7f7;
  border-radius: 6px;
}

@media (max-width: 480px) {
  .card-sec .card {
    width: 100%;
  }

  .card-sec .card-body {
    padding-bottom: 0;
  }

  /* .card-sec .card:nth-child(4),
  .card-sec .card:nth-child(5),
  .card-sec .card:nth-child(6) {
    display: none;
  } */
}

@media (min-width: 481px) and (max-width: 768px) {
  .card-sec .card {
    width: 48%;
    margin: 0.75%;
  }

  .card-sec .card-body {
    padding-bottom: 0;
  }

  .card-sec .card-title {
    font-size: 18px;
    line-height: 21px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .card-sec .card-title {
    font-size: 18px;
    line-height: 21px;
  }
}

/* ends of how-spritzer-works section */

/* rest card */
.rest-card {
  display: block;
  float: left;
  position: relative;
  width: 31%;
  margin: 2% 1.1%;
  border-radius: 5px;
  height: 385px;
}

.rest-card .img-div {
  position: relative;
  height: 170px;
  overflow: hidden;
}

.rest-card .img-div img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}

.rest-card .img-div img:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.rest-card .img-div p {
  position: absolute;
  color: #2f2f2f;
  padding: 1px 10px 1px 10px;
  border-radius: 25px;
  font-size: 13px;
  top: 10px;
  right: 10px;
  background: #dedede;
  font-weight: 600;
  margin-bottom: 0;
  text-transform: uppercase;
}

.rest-card .name {
  font-weight: 600;
  margin: 10px;
  height: 24px;
  font-size: 18px;
  overflow: hidden;
}

.rest-card .cusines {
  margin: 10px;
  font-size: 14px;
  color: #717171;
  height: 65px;
  overflow: hidden;
  text-align: left;
}

.rest-card .payment-method {
  display: inline-block;
  background: #e2e2e2;
  color: #1f1f1f;
  padding: 3px 10px 2px 10px;
  border-radius: 23px;
  margin: 5px 0 10px 10px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.rest-card .min-spend {
  display: inline-block;
  background: #1f1f1f;
  color: #e2e2e2;
  padding: 3px 10px 2px 10px;
  border-radius: 23px;
  margin: 5px 0 5px 10px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
}

.user-account-info-popup {
  background: #fff;
  border: 1px solid #ccc;
  border-color: rgba(0, 0, 0, 0.2);
  color: #000;
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  outline: none;
  overflow: hidden;
  animation: gb__a 0.2s;
  -webkit-user-select: text;
  width: 354px;
  padding: 2rem 1rem 1rem 1rem;
  text-align: center;
  border-radius: 5px;
}

.user-account-info-popup .dropdown-item {
  text-align: left;
  font-size: 0.9rem;
}

.user-account-info-popup hr {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.display-user-name-nav {
  font-size: 18px;
  font-weight: 500;
}

.display-user-email-nav i {
  margin-left: 5px;
  font-size: 12px;
  color: #2979ff;
}

.display-user-email-nav {
  margin-top: 5px;
  color: #555555;
  margin-bottom: 3px;
  font-size: 0.9rem;
}

.nav-user-active-state {
  color: #2979ff;
  width: 100%;
  display: block;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0.7rem;
  cursor: default;
}

.nav-user-info {
  pointer-events: none;
}

.category-wrapper {
  position: relative;
}

.category-scool-element {
  position: absolute;
  display: block;
  top: -90px;
}

@media (max-width: 768px) {
  .nav-mobile-view {
    display: block;
    padding: 8px 10px;
    border-radius: 5px;
    background-color: hsla(0, 0%, 51%, 0.41);
  }

  .nav-go-to-menu.dropdown-item {
    color: #222222;
    padding: 0.6rem 1.5rem;
    border-bottom: 1px solid #eaeaea;
  }

  .nav-go-to-menu.dropdown-item:last-child{
    border-bottom: none;
  }

  .nav-go-to-menu.dropdown-item i {
    font-size: 0.9rem;
    color: #495057;
    vertical-align: middle;
    margin-right:7px;
    width: 15px;
    text-align: center;
  }

  .nav-mobile-view .dropdown-item i {
    font-size: 0.9rem;
    color: #495057;
    vertical-align: middle;
    margin-right:7px;
    width: 15px;
    text-align: center;
  }
  .nav-mobile-view i {
    font-size: 27px;
    vertical-align: bottom;
  }
  .login-reg-popup {
    padding: 6px 15px 15px 15px;
  }
}

@media (max-width: 520px) {
  .navbar {
    flex-wrap: nowrap;
  }

  .user-name {
    display: none;
  }

  .displayUserNameMobile {
    padding: 5px 0 10px 22px;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #dee2e6;
  }

  .shopping-cart {
    top: 1px;
    right: -7px;
  }

  .navbar > .container-fluid {
    padding: 0;
    position: relative;
  }
  .user-display-btn {
    position: unset;
  }
  .user-account-info-popup {
    left: unset;
    right: 0;
    width: 100%;
  }
}

@media (max-width: 480px) {
  .rest-card {
    display: block;
    width: 92%;
    height: 340px;
    margin: 5px 4%;
  }

  .rest-card .img-div {
    height: 160px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (min-width: 481px) and (max-width: 650px) {
  .rest-card {
    width: 45%;
    height: 320px;
    margin: 2% 2.5%;
  }

  .rest-card .img-div {
    height: 120px;
  }

  .rest-card .name {
    height: 40px;
  }

  .rest-card .cusines {
    height: 85px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (min-width: 651px) and (max-width: 768px) {
  .rest-card {
    width: 45%;
    height: 350px;
    margin: 2% 2.5%;
  }

  .rest-card .img-div {
    height: 140px;
  }

  .rest-card .name {
    height: 48px;
  }

  .rest-card .cusines {
    height: 85px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .rest-card {
    width: 47%;
    height: 330px;
    margin: 2% 1.5%;
  }

  .rest-card .img-div {
    height: 140px;
  }

  .rest-card .cusines {
    height: 85px;
  }

  .rest-card span {
    padding: 5px 7px 3px 7px;
  }
}

/* thanking */
.thanking-letter-div {
  max-width: 1000px;
  margin: auto;
}

.author {
  font-family: "Dancing Script", cursive;
  text-align: center;
  margin-bottom: 0;
  font-weight: 500;
}

.founder {
  text-align: center;
  margin-bottom: 0;
  color: #737373;
  font-size: 15px;
}

.footer-sec {
  padding: 30px 40px 0 40px;
}

.col-social {
  width: 60%;
}

.col-rest-about {
  width: 40%;
  text-align: right;
}

.rest-logo-footer {
  float: right;
  width: auto;
  max-width: 130px;
  height: 35px;
  object-fit: contain;
  object-position: right;
}

.footer-shop-name {
  text-align: right;
  padding-top: 8px;
}

.social-dev {
  padding-top: 10px;
}

.social-icon {
  width: 25px;
  margin-right: 10px;
}

.shop-copy-right {
  text-align: center;
  font-size: 13px;
  margin-top: 30px;
  margin-bottom: 5px;
}

.footer-restaurant.info {
  margin-top: 2rem;
}

@media (max-width: 480px) {
  .footer-sec {
    padding: 30px 20px 0 20px;
  }

  .col-social {
    width: 100%;
    order: 2;
  }

  .col-rest-about {
    width: 100%;
    order: 1;
    text-align: center;
  }

  .rest-logo-footer {
    float: left;
    margin-bottom: 10px;
  }

  .footer-shop-name {
    text-align: left;
    padding-bottom: 5px;
  }

  .social-icon {
    width: 20px;
  }

  .shop-copy-right {
    margin-top: 20px;
    margin-bottom: 8px;
  }
}

@media (min-width: 481px) and (max-width: 575px) {
  .footer-sec {
    padding: 30px 17px 0 17px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .footer-sec {
    padding: 30px 22px 0 22px;
  }
}

@media (min-width: 1280px) {
  .footer-sec .cus-con-for-all {
    padding: 0 2.5%;
  }
}

:root {
  --main-background-color: #2d595b;
}

html {
  scroll-behavior: smooth;
}

.menu-header {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 400px;
  position: relative;
}

.menu-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(0, 0, 0, 0.3)),
      to(rgba(0, 0, 0, 0.3))
    ),
    -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.3)), to(transparent));
  z-index: 0;
}

.res-home-notice {
  max-width: 900px;
  margin: auto;
  background-color: #ffffff;
  border: 2px solid #006dee;
  border-radius: 8px;
  padding: 15px 60px;
}

.res-home-notice h4 {
  text-align: center;
  font-size: 17px;
  text-decoration: underline;
  margin-bottom: 1rem;
}

.res-home-notice p {
  margin-bottom: 0;
  font-size: 15px;
}

.menu-header .header-rest-main-div {
  max-width: 700px;
  margin: auto;
  margin-top: 105px;
}

.rest-store-details .cuisines-para {
  margin-bottom: 8px;
}

.rest-store-details .cuisines i {
  font-size: 6px;
  vertical-align: 3px;
  margin-right: 4px;
  color: #8c8c8c;
}

.rest-store-details .cuisines {
  font-size: 14px;
  line-height: 16px;
  color: #333333;
  margin-right: 10px;
  display: inline-block;
}

.menu-header .header-rest-info-div {
  height: auto;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  z-index: 2;
  text-align: center;
}

.menu-header .header-rest-info-div .rest-name {
  color: #ffffff;
  margin-top: 0;
  font-size: 40px;
  margin-bottom: 2rem;
}

.rest-logo-main {
  max-width: 80px;
  margin-bottom: 1rem;
}

.menu-header .header-rest-info-div .CTA-mobile {
  display: none;
}

.menu-header .header-rest-info-div .CTA-non-mobile {
  display: inline;
}

.menu-header .header-rest-info-div .CTA-mobile,
.menu-header .header-rest-info-div .CTA-non-mobile {
  background: #e6e6e6;
  font-size: 12px;
  color: #000;
  padding: 4px 9px;
  border-radius: 25px;
  vertical-align: middle;
  text-decoration: none;
  font-weight: 600;
  height: 20px;
}

.menu-header .header-rest-info-div .address {
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  font-weight: 500;
}

.res-open-lable,
.res-closed-lable {
  font-size: 14px;
  border-radius: 5px;
  padding: 2px 8px;
}

.res-more-info {
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  margin-top: 0.5rem;
  display: inline-block;
  text-decoration: underline;
}

.res-home-order-modification {
  padding: 2rem 0;
}

.payment-methods-icon svg {
  width: 42px;
  margin-right: 10px;
}

.res-home-order-modification .order-time-change {
  margin-bottom: 0;
}

.menu-header .header-rest-info-div .rest-desc {
  margin-top: 15px;
  height: 40px;
  font-size: 14px;
  color: #737373;
  overflow: hidden;
  margin-bottom: 0;
}

.menu-header .delivery-details-div {
  position: relative;
  height: auto;
  border: 2px solid;
  padding: 15px;
  margin-top: 32px;
  border-radius: 5px;
}

.menu-header .delivery-details-div .del-heading {
  position: absolute;
  font-size: 20px;
  top: -18px;
  left: 15px;
  background: #fff;
  padding: 0 10px;
  font-weight: 600;
}

.menu-header .delivery-details-div .info {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

#order-accepting-hours {
  display: inline;
  padding-right: 10px;
}

.filter-bar {
  top: 65px;
  overflow: auto;
  white-space: nowrap;
}

.filter-badge {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  transform: translateZ(0px);
  padding: 9px 20px;
  border-radius: 5px;
  line-height: 50px;
}

.active-filter {
  background: #000;
  color: #fff;
  padding: 9px 20px;
  margin-left: 15px;
}

.active-filter:hover {
  color: #fff !important;
}

.filter-badge:hover {
  color: inherit;
  text-decoration: none;
}

.menu-items-sec .menu-headings {
  position: relative;
  font-weight: 900;
  font-size: 30px;
}

.rest-details-map {
  height: 400px;
  background-color: #dee2e6;
}

.rest-details-map img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #b15555;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #302558;
  position: absolute;
  border-radius: 50%;
}
.pulse {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}
.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  box-shadow: 0 0 1px 2px #b15555;
  animation-delay: 1.1s;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
@keyframes bounce {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    transform: translateY(-2000px) rotate(-45deg);
  }
  60% {
    opacity: 1;
    -ms-filter: none;
    -webkit-filter: none;
            filter: none;
    transform: translateY(30px) rotate(-45deg);
  }
  80% {
    transform: translateY(-10px) rotate(-45deg);
  }
  100% {
    transform: translateY(0) rotate(-45deg);
  }
}

.rest-store-details {
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.store-details-wrapper {
  margin-bottom: 4rem;
}

.rest-store-details-full .CTA-mobile {
  color: #212529;
  text-decoration: none;
}

.rest-store-details-desc {
  margin-top: 1rem;
}

.rest-store-details-hours span {
  margin-left: 0.5rem;
}

.rest-store-details-open-hours-table {
  display: flex;
}

.rest-store-details-full {
  margin-top: 3rem;
}

.rest-store-details-title {
  font-weight: 500;
  margin-bottom: 10px;
}

.rsd-details-block {
  margin-top: 2rem;
}

.minimum-order-values-wrapper {
  display: flex;
  margin-top: 1rem;
}

.minimum-order-values-block {
  margin-bottom: 1rem;
  margin-right: 3rem;
  display: flex;
}

.minimum-order-values-block .radio-icon {
  width: 20px;
  margin-right: 15px;
}

.minimum-order-values-block p {
  margin-bottom: 0;
}

.rest-contact-block {
  display: flex;
  align-items: baseline;
}

.disabled-select-option {
  color: rgb(170, 170, 170);
  pointer-events: none;
}

.schedule-onclosed-wrapper {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.schedule-onclosed-wrapper:hover {
  text-decoration: underline;
}

/* cards */
.menu-item-card {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.menu-item-card-inner {
  background-color: #f2f2f2;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}

.menu-item-card-empty {
  display: block;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 15px;
  margin-bottom: 15px;
}

.menu-item-card .img-div {
  position: relative;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
}

.menu-item-card .img-div img {
  width: 100%;
  border-radius: 5px 5px 0 0;
  height: 100%;
  object-fit: cover;
}

.menu-item-card .img-div .out-of-stock {
  position: absolute;
  color: rgb(46, 46, 46);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  font-size: 14px;
  padding: 3px 7px;
  top: 8px;
  right: 10px;
}

.menu-item-card-empty .out-of-stock {
  position: absolute;
  color: rgb(46, 46, 46);
  background: rgb(255, 255, 255);
  border-radius: 25px;
  font-size: 14px;
  padding: 3px 7px;
  top: 8px;
  right: 25px;
}

.menu-item-card .name,
.menu-item-card-empty .name {
  font-weight: 600;
  margin: 10px;
  font-size: 16px;
}

.menu-item-card .desc,
.menu-item-card-empty .desc {
  margin: 0 10px;
  font-size: 14px;
  color: #717171;
  flex-grow: 1;
}

.menu-item-card-empty .desc {
  min-height: 25px;
}

.menu-item-card .price,
.menu-item-card-empty .price {
  color: rgb(51, 51, 51);
  margin: 0px;
  font-size: 15px;
  font-weight: 900;
  position: absolute;
  bottom: 10px;
}

.menu-item-card .discounted-price,
.menu-item-card-empty .discounted-price {
  color: rgb(51, 51, 51);
  margin: 0px;
  font-size: 12px;
  /* font-weight: 900; */
  position: relative;
  bottom: 12px;
  text-decoration: line-through;
}

.card-action-price-cart {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  margin-top: 15px;
  height: 33px;
}

.menu-item-card .dissable-cart-btn,
.menu-item-card-empty .dissable-cart-btn {
  position: absolute;
  right: 10px;
  bottom: 10px;
  background: #e6e6e6;
  color: rgb(115, 115, 115);
  padding: 8px 10px 7px 10px;
  border-radius: 5px;
  border: 0;
  font-size: 12px;
  font-weight: 600;
  cursor: not-allowed;
}

.menu-item-card .cart-btn,
.menu-item-card-empty .cart-btn {
  position: absolute;
  color: #fff;
  padding: 8px 10px 7px 10px;
  border-radius: 5px;
  border: 0;
  bottom: 10px;
  font-size: 12px;
  right: 10px;
  font-weight: 600;
  z-index: -1;
}

.menu-item-card .cart-btn:hover,
.menu-item-card-empty .cart-btn:hover {
  background: #e23131;
  cursor: pointer;
}

.menu-item-card .cart-btn:focus,
.menu-item-card-empty .cart-btn:focus {
  outline: 0;
}

/* map modal */
.map-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 0, 0);
  z-index: 1;
  background: #fff !important;
  padding: 1px 8px 4px 10px !important;
  border-radius: 50%;
  opacity: 1;
  font-weight: 400;
  font-size: 30px;
}

.map-close:focus {
  outline: 0;
}

.card-action-div {
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 5px;
}

.see-more-link {
  font-size: 12px;
  line-height: 14px;
  color: #006dee;
  cursor: "pointer";
}

.category-wrapper {
  padding: 40px 0 0 0;
}

.menu-cards-container {
  margin-bottom: 15px;
}

.addons-loading-spinner {
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f7f7f7;
}

.addons-loading-spinner .spinner-border {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  color: #808080;
}

.spinner-color-addon {
  color: #cacaca;
}

.dine-in-main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
}

.select-dine-in-table-modal-body {
  padding: 1rem;
}

.select-table-container {
  position: relative;
  height: 45px;
  width: 100px;
  margin: 5px;
}

.table-select-radio-button {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
}

.table-select-radio-button:checked + .table-select-radio-tile {
  border-color: #2d595b;
  border-color: var(--main-background-color, #2d595b);
  box-shadow: 0 0 0 1px #2d595b inset;
  box-shadow: 0 0 0 1px var(--main-background-color, #2d595b) inset;
  background-color: #2d595b;
  background-color: var(--main-background-color, #2d595b);
}

.table-select-radio-button:checked
  + .table-select-radio-tile
  .table-select-radio-tile-label {
  color: #ffffff;
  font-weight: 600;
}

.table-select-radio-button:hover + .table-select-radio-tile {
  border-color: #b7b7b7;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  transition: all 300ms;
}

.table-select-radio-button:checked:hover + .table-select-radio-tile {
  border-color: #2d595b;
  border-color: var(--main-background-color, #2d595b);
  box-shadow: 0 0 0 1px #2d595b inset,
    0 2px 12px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 1px var(--main-background-color, #2d595b) inset,
    0 2px 12px rgba(0, 0, 0, 0.1);
}

.table-select-radio-tile {
  align-items: center;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  height: 45px;
  justify-content: center;
  transition: all 300ms;
  width: 100px;
}

.table-select-radio-tile-label {
  color: #484848;
  font-size: 15px;
  text-align: center;
  margin: 0;
}

.table-select-action-buttons {
  margin-top: 2rem;
  text-align: center;
}

.table-select-action-buttons button {
  margin: 0 3px;
}

.table-select-button {
  color: #fff;
  padding: 8px 15px 7px 15px;
  border-radius: 5px;
  border: 0;
  bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.table-select-button:disabled {
  background: #e6e6e6 !important;
  color: rgb(115, 115, 115);
  padding: 8px 15px 7px 15px;
  border-radius: 5px;
  border: 0;
  bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  cursor: default;
}

.dine-in-selectbox {
  width: 80%;
}

@media (max-width: 975px) {
  .menu-header {
    height: auto;
    margin-bottom: unset !important;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .menu-header .header-rest-info-div {
    border-radius: 0;
  }

  .menu-header .header-rest-info-div .rest-desc {
    height: auto;
  }

  .minimum-order-values-wrapper {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .filter-bar {
    top: 65px;
  }

  .col-social {
    width: 100%;
  }

  .col-rest-about {
    width: 100%;
    text-align: left;
    margin-top: 2rem;
    font-size: 0.95rem;
  }

  .social-dev {
    padding-top: 0;
  }

  .rsd-minimum-order-value.rsd-details-block {
    margin-bottom: 1rem;
  }

  .rsd-delivering-to.rsd-details-block {
    display: inline-block;
    margin-right: 4rem;
  }

  .rsd-delivering-fee.rsd-details-block {
    display: inline-block;
  }
  .hide-on-mobile {
    display: none;
  }
  .shopping-cart .cart-badge {
    top: -10px !important;
    left: 12px !important;
  }
  .shopping-cart {
    margin-bottom: -5px !important;
  }
  .user-display-btn .dropdown-toggle:after {
    display: none;
  }
  .user-display-btn {
    padding: 8px 8px;
  }
  .shopping-cart .fa-shopping-cart {
    font-size: 23px;
  }
}

@media (max-width: 575px) {
  .menu-header .delivery-details-div {
    margin-top: 30px;
  }

  .menu-header .header-rest-info-div {
    height: auto;
    box-shadow: none;
  }

  .menu-header .header-rest-info-div .CTA-mobile {
    display: inline-block;
    cursor: pointer;
  }

  .menu-header .header-rest-info-div .CTA-non-mobile {
    display: none;
  }

  .menu-header .header-rest-info-div .CTA-mobile,
  .menu-header .header-rest-info-div .CTA-non-mobile {
    padding: 3px 9px 5px 9px;
  }

  #order-accepting-hours {
    display: block;
  }
  .menu-header .header-rest-main-div {
    margin-top: 180px;
  }
  .menu-item-card-inner {
    flex-direction: row;
  }
  .card-action-price-cart {
    margin-top: 10px;
  }
  .menu-item-card .img-div {
    height: 100%;
    width: 133px;
    max-width: 133px;
    min-width: 133px;
    border-radius: 5px;
    min-height: auto;
    max-height: unset;
  }
  .menu-item-card .price,
  .menu-item-card-empty .price {
    font-size: 15px;
  }
  .menu-item-card-empty .name {
    margin-left: 10px;
    margin-right: 10px;
  }
  .menu-item-card-empty .card-action-price-cart {
    padding-left: 10px;
    padding-right: 10px;
  }
  .filter-bar {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .menu-item-card-empty .out-of-stock {
    top: 23px;
  }
  .menu-item-card .desc,
  .menu-item-card-empty .desc {
    max-height: 46px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .menu-item-card .img-div .out-of-stock {
    right: 21px;
    font-size: 13px;
  }
  .menu-item-card .name,
  .menu-item-card-empty .name {
    font-size: 14px;
  }
  #menu-item-details-modal-close span {
    vertical-align: 2px !important;
    color: black;
  }
  .menu-header .header-rest-info-div .address {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .menu-item-card span {
    padding: 5px 7px 3px 7px;
  }

  .menu-item-card .dissable-cart-btn,
  .menu-item-card-empty .dissable-cart-btn {
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 11px;
  }

  .filter-badge {
    font-size: 13px;
  }
}

@media (max-width: 420px) {
  .menu-item-card .cart-btn,
  .menu-item-card-empty .cart-btn {
    padding: 6px 8px;
    border-radius: 4px;
    font-size: 11px;
  }

  .menu-header .header-rest-info-div .rest-name {
    font-size: 32px;
    margin-bottom: 1rem;
  }

  .menu-item-card .img-div {
    height: 100%;
    max-width: 90px;
    min-width: 90px;
    border-radius: 5px;
    min-height: auto;
    max-height: unset;
  }

  .menu-item-card .img-div .out-of-stock {
    right: 13px;
    font-size: 10px;
  }

  .rest-logo-main {
    max-width: 56px;
  }
  .rest-store-details-desc {
    font-size: 13px;
  }

  .rest-store-details .cuisines {
    font-size: 13px;
  }

  .rest-store-details {
    margin-top: 2rem;
  }
  .rest-store-details-title {
    font-size: 14px;
  }

  .minimum-order-values-block p,
  .rest-contact-block p,
  .rest-contact-block a,
  .rsd-delivering-to.rsd-details-block,
  .rsd-delivering-fee.rsd-details-block {
    font-size: 14px;
  }

  .rest-contact-block i {
    font-size: 13px;
  }

  .payment-methods-icon svg {
    width: 36px;
  }
  .minimum-order-values-block .radio-icon {
    width: 17px;
  }
}

@media (min-width: 991px) and (max-width: 1200px) {
  .menu-item-card .img-div {
    min-height: 150px;
    max-height: 150px;
  }
}

@media (min-width: 576px) and (max-width: 990px) {
  .menu-item-card .img-div {
    min-height: 150px;
    max-height: 150px;
  }
}

@media (min-width: 651px) and (max-width: 768px) {
  .menu-item-card .desc {
    min-height: 38px;
    height: auto;
  }

  .menu-item-card span {
    padding: 5px 7px 3px 7px;
  }
}

@media (max-width: 350px) {
  .menu-header .delivery-details-div {
    margin-top: 30px;
  }
}

/* navbar */
.navbar-rest-name:hover {
  text-decoration: none;
}

.navbar-rest-name {
  display: block;
}

.delivery-pickup-dine-select {
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
}

.delivery-pickup-dine-select p {
  padding-left: 15px;
  margin-bottom: 0;
  line-height: 30px;
}

.delivery-pickup-dine-select p:hover {
  background: rgb(226, 226, 226);
}

.navbar-nav .shopping-cart {
  position: relative;
  width: 50px;
}

.logged-user {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.user-name {
  color: #fff;
  font-size: 13px;
  padding-top: 5px;
  padding-right: 10px;
}

.login-btn {
  color: #fff;
  padding: 10px;
  background-color: hsla(0, 0%, 51%, 0.41);
  border-radius: 5px;
  cursor: pointer;
  z-index: 999999 !important;
}

.login-btn i {
  font-size: 16px;
  vertical-align: middle;
  margin-right: 4px;
}

.shopping-cart {
  position: relative;
  margin-right: 1.5rem;
  cursor: pointer;
  margin-bottom: -6px;
}

.shopping-cart .fa-shopping-cart:hover {
  transform: scale(1.05);
}

.shopping-cart .cart-badge {
  position: absolute;
  color: #fff;
  padding: 0px 7px;
  border-radius: 50%;
  top: -10px;
  left: 12px;
  font-size: 14px;
  font-weight: 600;
}

.shopping-cart .fa-shopping-cart {
  font-size: 25px;
}

.cart-sec {
  max-width: 570px;
  margin: auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 6px;
}

.cart-sec.popup {
  width: 100%;
  padding: 0;
}

h5.cart-heading {
  margin: 0;
  font-size: 17px;
}

.cart-sec .cart-heading {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
  color: #222222;
}

.cart-sec .deliver-from {
  font-size: 17px;
}

.cart-desktop {
  display: block;
}

.cart-sec .item-table tr td {
  border: 0;
}

.cart-sec .item-table tr td:nth-child(1) {
  padding-left: 10px;
  padding-right: 3px;
  font-weight: 600;
  font-size: 14px;
  width: 30px;
}

.cart-sec .item-table tr td:nth-child(2) {
  min-width: 120px;
  color: #222222;
  font-weight: 500;
  padding-top: 0.9rem;
  font-size: 14px;
}

.cart-sec .item-table tr td:nth-child(3) {
  font-weight: 600;
  font-size: 14px;
  color: #757575;
  min-width: 100px;
  text-align: right;
  padding-top: 0.9rem;
}

.cart-sec .item-table tr td:first-child {
  border-radius: 6px 0 0 6px;
}

.cart-sec .item-table tr td:last-child {
  border-radius: 0 6px 6px 0;
}

.cart-sec .item-table tr td:nth-child(4) {
  min-width: 30px;
  padding-right: 0;
  text-align: right;
}

.order-qty {
  padding: 4px 8px;
  background-color: #e9ecef;
  border-radius: 4px;
  border: 0;
  cursor: pointer;
  border-right: 4px solid #e9ecef;
}

.cart-sec.popup .item-table tr td:nth-child(1) {
  padding-left: 0;
  padding-right: 3px;
  font-weight: 600;
  font-size: 14px;
  width: 26px;
}

.cart-sec.popup .item-table tr td:nth-child(2) {
  min-width: 120px;
}

.cart-sec.popup .item-table tr td:nth-child(3) {
  font-weight: 600;
  font-size: 15px;
  color: #757575;
  min-width: 100px;
  text-align: right;
}

.cart-sec.popup .item-table tr td:nth-child(4) {
  min-width: 30px;
  padding-right: 0;
}

.cart-sec .dec-btn,
.cart-sec .inc-btn {
  color: #fff;
  padding: 3px 7px;
  border: 0;
  font-size: 14px;
  border-radius: 50%;
}

.cart-sec .dec-btn:hover,
.cart-sec .inc-btn:hover {
  background: rgb(82, 82, 82);
}

.cart-sec .dec-btn:focus,
.cart-sec .inc-btn:focus {
  outline: 0;
}

.cart-sec .qty {
  color: #000;
  padding: 0px 7px;
  font-weight: 600;
  margin: 0 5px;
}

.cart-sec .item-table .fa-trash-alt {
  color: #ef7272;
  cursor: pointer;
}

.cart-sec .item-table .fa-trash-alt:hover {
  color: red;
}

.cart-sec .total-sec {
  font-size: 14px;
}

.cart-sec .total-sec .col:nth-child(2) {
  text-align: right;
  padding-bottom: 10px !important;
}

.cart-sec .disabled-btn {
  background: #dcdcdc;
  color: #fff;
  border: 0;
  width: 100%;
  padding: 8px;
  margin-top: 6px;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 600;
}

.checkout-btn {
  text-align: center;
}

.cart-sec .checkout {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.25rem;
  transition: 0.3s;
  background-color: #2d595b;
}

.cart-sec .checkout:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.cart-sec.popup .checkout {
  color: #fff;
  border: 0;
  width: 100%;
  padding: 8px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 600;
}

.cart-sec.popup .checkout:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.additional-info-text-limit {
  width: 100%;
  display: block;
  text-align: right;
  font-size: 11px;
  font-weight: 400;
  margin-top: 5px;
}

/* .cart-sec .checkout:hover {
  opacity: 0.8;
} */

.cart-sec .checkout:focus {
  box-shadow: none;
  outline: 0;
}

/* cart in mobile view */
.cart-mobile {
  display: none;
}

.cart-mobile .cart-item-name {
  padding-left: 0;
  font-weight: 600;
  font-size: 14px;
  min-width: 230px;
}

.cart-mobile .item-price {
  font-weight: 600;
  font-size: 15px;
  color: #757575;
  min-width: 100px;
  text-align: right;
  margin-bottom: 0;
}

.cart-mobile .item-del {
  color: #ef7272;
  cursor: pointer;
  margin-bottom: 40px;
}

.cart-mobile .qty {
  color: #545454;
  padding: 0px 7px;
  font-weight: 500;
  font-size: 15px;
  margin: 0 4px;
}

.go-back-action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-order-total {
  font-weight: 600;
  font-size: 15px;
  color: #222222;
  padding-right: 0.75rem;
}

.match-min-order-checkbox {
  text-align: center;
}

.match-min-order-checkbox label {
  font-size: 12px;
  color: #006dee;
  margin: 0;
}

.match-min-order-checkbox input {
  margin-right: 6px;
  vertical-align: middle;
}

.min-height-viewport {
  min-height: 75.7vh !important;
}

.cart-details-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 500px;
}

.cart-details-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 270px;
}

.cart-popup .item-table tr {
  border-bottom: 1px solid #f6f6f6;
}

.cart-sec .checkout:disabled,
.cart-sec .checkout[disabled],
.schedule-btn[disabled] {
  background-color: #cccccc !important;
  color: #666666;
}

.page-background-grey {
  background-color: #f2f2f2;
}

.padding-top-bottom-25 {
  padding: 25px 0;
}

.go-back-action-buttons a {
  font-size: 13px;
}

.go-back-action-buttons a:hover {
  text-decoration: underline !important;
}

.go-back-action-buttons a i {
  margin-right: 8px;
  font-size: 14px;
  transition: 0.2s;
}

.go-back-action-buttons a:hover i {
  color: #222222;
  transform: translateX(-2px);
}

.forgot-password-main .update-bank-model-heading,
.forgot-password-main .login-or-reg-buttons {
  margin: 0;
}

.empty-cart-notify {
  text-align: center;
}

.remove-cart-item {
  display: block;
  font-size: 10px;
  margin-top: 7px;
  font-weight: 500;
  text-decoration: underline;
  color: #2d595b;
}

.remove-cart-item span {
  cursor: pointer;
}

.remove-cart-item span:hover {
  color: #006dee;
}

.total-sec.subtotal {
  margin-bottom: 2rem;
  margin-top: 1.5rem;
}

.cart-items-popup-display .container {
  padding-left: 0;
  padding-right: 0;
  padding: 20px;
}

.cart-items-popup-display > .close-cart {
  position: absolute;
  top: 6px;
  right: 20px;
  font-size: 30px;
  cursor: pointer;
}

.cart-main {
  position: fixed;
  top: 65px;
  right: 0;
  background-color: #ffffff;
  max-width: 500px;
  min-width: 500px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-cart span:hover {
  color: red;
}

.cus-con-for-all.cart-main {
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0 0 0 6px;
  max-height: 73vh;
  overflow-y: auto;
  cursor: default;
}

/* custom scrollbar */
.cus-con-for-all.cart-main::-webkit-scrollbar {
  width: 20px;
}

.cus-con-for-all.cart-main::-webkit-scrollbar-track {
  background-color: transparent;
}

.cus-con-for-all.cart-main::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

.cus-con-for-all.cart-main::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.finalized-order-info {
  display: flex;
  color: inherit;
  margin-left: 2rem;
  font-size: 0.95rem;
}

.finalized-order-type {
  margin-right: 2rem;
}

.finalized-order-type i,
.finalized-order-time i {
  margin-right: 0.6rem;
}

.menu-item-image img {
  object-fit: contain;
  width: 100%;
  max-height: 300px;
}

.menu-item-image {
  min-height: -webkit-min-content;
  min-height: -moz-min-content;
  min-height: min-content;
  max-height: -webkit-min-content;
  max-height: -moz-min-content;
  max-height: min-content;
  width: 100%;
  overflow: hidden;
  text-align: center;
  border-radius: 0.4rem;
  background-color: #ededed;
}

.menu-item-details-popup {
  padding: 0;
  border-radius: 0.5rem;
}

.menu-item-details-modal-body {
  position: relative;
}

#menu-item-details-modal-close {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  color: #535353;
  background-color: #ffffff;
  opacity: 1;
  z-index: 1000;
}

#menu-item-details-modal-close:hover {
  background-color: rgb(212 212 212);
}

#menu-item-details-modal-close:focus {
  outline: 0;
}

#menu-item-details-modal-close span {
  vertical-align: 2px;
  font-size: 30px;
}

.addon-error {
  font-size: 13px;
  margin-bottom: 1rem;
  display: block;
  color: red;
}

.menu-item-details-modal-body-inner {
  padding: 15px;
}

.menu-item-details-description p {
  font-size: 14px;
  line-height: 1.5;
}

.addon-price {
  font-weight: 400;
}

.item-details-cart-btn {
  color: #fff;
  padding: 8px 10px;
  border-radius: 5px;
  border: 0;
  right: 10px;
  bottom: 12px;
  font-size: 12px;
  font-weight: 700;
  width: 100%;
}

.item-details-cart-btn:hover,
.item-details-cart-count:hover {
  -webkit-filter: brightness(1.07);
          filter: brightness(1.07);
}

.add-to-cart-btn-total {
  font-weight: 500;
}

.menu-item-details-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  border-top: 1px solid #ededed;
  padding: 1rem 15px 0;
  margin-left: -15px;
  margin-right: -15px;
}

.menu-item-couter {
  display: flex;
  align-items: center;
}

.add-to-cart-btn {
  width: 75%;
}

.menu-item-couter {
  width: 20%;
}

.menu-item-details-title h6 {
  line-height: 1.5;
}

.item-details-cart-count {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  outline: 0;
  border: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.item-details-cart-count:focus {
  outline: 0;
}

.count-display {
  margin: 0 10px;
}

.item-details-cart-count:disabled {
  background-color: #6c757d !important;
}

.addons-title-main {
  padding: 10px 15px;
  margin-left: -15px;
  margin-right: -15px;
  background-color: #ededed;
  margin-bottom: 1rem;
}

.addons-title-a {
  font-size: 14px;
}

.addons-title-b {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0;
}

.addon-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 1rem;
}

.addon-select input {
  padding-top: 5px;
  transform: scale(1.3);
  margin-top: -3px;
  vertical-align: middle;
  margin-right: 10px;
}

.addon-disabled {
  font-weight: 400;
  color: #cccccc;
}

.addon-select input:disabled + label {
  color: #cccccc;
}

.addon-select label {
  margin-bottom: 0;
}

.item-table-item-details {
  display: flex;
  width: 100%;
}

.item-table-item-details-item-name {
  width: 59%;
  font-size: 16px;
  font-weight: 500;
}

.item-table-item-details-item-count {
  width: 16%;
}

.item-table-item-details-item-price {
  width: 25%;
  text-align: right;
}

.addons-display-cart,
.cart-items-checkout-btn,
.portion-display-cart {
  padding-left: 16%;
}

.portion-display-cart {
  margin-bottom: 10px;
}

.addons-display-cart-title {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
}

.addons-display-cart-addon-item {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 1px;
}

.item-table-item-details-main {
  margin-bottom: 2rem;
}

.cart-continue-shopping {
  text-align: center;
  margin-top: 0.5rem;
}

.cart-continue-shopping a {
  font-size: 14px;
}

.cart-btn-full-card-size {
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.cart-btn-full-card-size:focus {
  outline: none;
}

.cart-continue-login {
  text-align: center;
  margin-top: 0.5rem;
}

.cart-continue-login a {
  font-size: 14px;
  color: #007bff;
  cursor: pointer;
}

.cart-continue-login a:hover {
  text-decoration: underline;
}

.pre_order_wrapper .item-details-cart-count {
  width: 26px;
  height: 26px;
  border-radius: 6px;
  outline: 0;
  border: 0;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
}

.pre_order_wrapper .menu-item-couter {
  width: auto;
}

.pre-order-title-main {
  padding: 10px 13px;
  background-color: #ededed;
  border-radius: 5px;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.pre-order-title-main i {
  color: #0075ff;
  margin-top: 2px;
  display: block;
}

@media (max-width: 768px) {
  .cart-main {
    top: 107px;
  }
  .finalized-order-info {
    margin-left: 0rem;
  }
}

@media (max-width: 520px) {
  .cart-mobile {
    display: block;
  }

  .cart-sec .dec-btn,
  .cart-sec .inc-btn {
    padding: 4px 6px 2px 6px;
    font-size: 12px;
  }

  .cart-sec .subtotal {
    margin-top: 5px;
  }

  .cart-main {
    top: 77px;
    width: 98%;
    right: 1%;
    min-width: auto;
    max-height: 90vh;
    overflow: auto;
    box-shadow: 9px 8px 13px 11px rgba(0, 0, 0, 0.1);
  }

  .login-register-popup-main .modal-open .modal {
    padding-right: 0;
  }

  /* Main Cart */
  /* .cart-sec .item-table tr {
    display: grid;
    background-color: #f7f7f7;
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .cart-sec .item-table tr td:nth-child(1) {
    width: auto;
    grid-row: 1/2;
    grid-column: 1/3;
    padding: 0;
  }

  .cart-sec .item-table tr td:nth-child(2) {
    grid-row: 1/2;
    grid-column: 2/3;
    min-width: auto;
    padding: 0;
  }

  .cart-sec .item-table tr td:nth-child(3) {
    min-width: auto;
    grid-row: 1;
    grid-column: 3/3;
    padding: 0;
  }

  .cart-sec .item-table tr td {
    padding: 0;
  } */

  .cart-sec .item-table tr td:nth-child(1) {
    font-size: 12px;
    width: 10%;
  }

  .cart-sec .item-table tr td:nth-child(2) {
    font-size: 12px;
    width: 70%;
  }

  .cart-sec .item-table tr td:nth-child(3) {
    font-size: 12px;
    padding-right: 0;
    width: 20%;
    min-width: 94px;
  }

  .order-qty {
    padding: 4px 4px;
  }

  .cart-order-total {
    font-size: 13px;
  }

  .cart-sec .cart-heading {
    font-size: 14px;
  }

  .match-min-order-checkbox {
    text-align: left;
    display: flex;
  }

  .match-min-order-checkbox input {
    margin-top: 3px;
  }

  .radio-tile-group .input-container {
    height: 4rem;
    width: 4rem;
  }

  .radio-tile-group .input-container .radio-tile {
    display: flex;
    flex-direction: column;
  }

  .radio-icon {
    margin-right: 0;
  }

  .radio-tile-group .input-container .radio-tile-label {
    font-size: 10px;
    margin-top: 5px;
  }

  .remove-cart-item {
    margin-top: 5px;
  }

  .cart-sec .checkout {
    width: 75%;
  }

  .cart-sec .item-table tr {
    background-color: #f7f7f7;
    margin-bottom: 10px;
    display: block;
    padding: 7px;
    border-radius: 5px;
  }

  .cart-sec .item-table tr td {
    padding: 5px 4px !important;
  }

  .nav-go-to-menu-main {
    align-items: center;
  }

  .logged-user {
    width: 25px;
    height: 25px;
  }

  .cus-con-for-all.cart-main {
    border-radius: 5px;
  }
  .finalized-order-info {
    margin-left: 0;
    flex-direction: column;
    font-size: 12px;
  }
  .finalized-order-type i,
  .finalized-order-time i {
    width: 18px;
    margin-right: 0;
  }
}

@media (max-width: 480px) {
  .delivery-pickup-dine-select {
    font-size: 12px;
  }
  .item-details-cart-count {
    min-width: 32px;
  }

  .add-to-cart-btn {
    width: 67% !important;
  }

  .delivery-pickup-dine-select .dropdown-menu {
    min-width: 10rem;
  }
}

@media (max-width: 420px) {
  h5.cart-heading {
    font-size: 16px;
  }

  .add-to-cart-btn {
    width: 66%;
  }
  .item-table-item-details-item-count {
    width: 18%;
  }
  .addons-display-cart,
  .cart-items-checkout-btn,
  .portion-display-cart {
    padding-left: 18%;
  }
  .item-table-item-details-item-name {
    font-size: 15px;
  }
  .item-table-item-details-item-price {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .navbar-rest-name h3 {
    font-size: 20px !important;
  }
}

@-moz-document url-prefix() {
  .shopping-cart .fa-shopping-cart {
    bottom: auto;
  }

  .shopping-cart .cart-badge {
    top: 0px;
  }
}

@media (max-width: 340px) {
  .finalized-order-info {
    display: none;
  }
  .item-table-item-details-main {
    overflow: auto;
  }
  .item-table-item-details-item-count {
    min-width: 56px;
  }
  .item-table-item-details-item-name {
    min-width: 140px;
  }
  .item-table-item-details-item-price {
    min-width: 75px;
  }
}

@media (max-width: 576px) {
  .modal-dialog {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
  }

  .menu-item-image {
    border-radius: 0;
  }

  .menu-item-image img {
    object-fit: contain;
    width: 100%;
    max-height: 270px;
  }

  .modal-dialog-centered {
    align-items: flex-start;
  }
}

.user-details-form label,
.guest-details-in label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
}

.user-details-form .form-control:focus {
  outline: 0;
  box-shadow: none;
  border: 1px solid #000;
}

.react-tel-input {
  width: 300px;
  margin: auto;
}

.user-details-form select {
  font-size: 15px;
}

.user-details-form select:hover {
  cursor: pointer;
}

.user-details-form select option {
  font-size: 14px;
}

.add-address-btn {
  background: #ffffff;
  border: 0;
  padding: 7px 0;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 70px;
  border-radius: 5px;
}

.add-address-btn:hover {
  color: #e43026;
}

.add-address-btn:focus {
  outline: 0;
  box-shadow: none;
}

.add-phone-btn {
  display: block;
  background: #ffffff;
  border: 0;
  padding: 7px 0 0 0;
  margin-top: 0px;
  font-size: 15px;
  font-weight: 600;
  width: 15%;
  border-radius: 5px;
  text-align: left;
}

/* .add-phone-btn:hover {
  color: #e43026;
} */

.add-phone-btn:focus {
  outline: 0;
  box-shadow: none;
}

.styles_react-code-input__CRulA input:nth-child(1) {
  border: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(2) {
  border-top: 1.5px solid #f5756e !important;
  border-bottom: 1.5px solid #f5756e !important;
  border-right: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(3) {
  border-top: 1.5px solid #f5756e !important;
  border-bottom: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:nth-child(4) {
  border: 1.5px solid #f5756e !important;
  color: #f5756e;
}

.styles_react-code-input__CRulA input:focus {
  border: 2px solid #505050 !important;
}

.select-city-btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 15px;
  background: #ff6961;
  color: #fff;
  border-radius: 3px;
  padding: 2px 10px;
  margin-bottom: 0;
  margin-top: -35px;
  cursor: pointer;
}

.select-city-btn:hover {
  background: #e63b32;
}

.save-address {
  width: 50%;
  color: #fff;
  border: 0;
  margin-top: 6px;
  margin-bottom: -24px;
  background: #2d595b;
  padding: 7px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 0.25rem;
}

.save-address:focus {
  outline: 0;
  box-shadow: none;
}

.send-to-verify {
  background: #2d595b;
  color: #fff;
  width: 50%;
  border: 0;
  padding: 7px;
  margin-top: 30px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  border-radius: 0.25rem;
}

.send-to-verify-button {
  text-align: center;
}

.send-to-verify:focus {
  outline: 0;
  box-shadow: none;
}

.send-code-again {
  text-align: center;
  color: #ff7068;
  margin-top: 15px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 13px;
}

.send-code-again:hover {
  text-decoration: underline;
}

.your-details-mobile-no p {
  background-color: #ededed;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.4rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.your-details-address-no p {
  background-color: #ededed;
  display: block;
  width: 100%;
  min-height: calc(1.5em + 0.75rem + 2px);
  padding: 0.4rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.add-phone-btn-guest {
  background-color: #ededed;
  display: block;
  width: 100%;
  height: calc(1.8em + 0.75rem + 2px);
  padding: 0.4rem 0.75rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-clip: padding-box;
  border-radius: 0.25rem;
  border: none;
}

.add-phone-btn-guest:focus {
  outline: none;
}
.your-details-mobile-no .text-success {
  float: right;
  margin-top: 2px;
}

#addressModal {
  font-size: 17px;
}

.yd-label-action-btn {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.yd-label-action-btn button {
  text-align: right;
  padding-right: 4px;
  font-weight: 500;
  color: #006dee;
}

.your-details-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.your-details-title h6 {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
}

.react-tel-input .form-control {
  color: #222222;
  background-color: #ededed;
  border: none;
  border-radius: 0.25rem;
}

.react-tel-input .form-control:focus {
  border: none;
}

.react-tel-input .flag-dropdown {
  border: none;
  background-color: #ededed;
}

.react-tel-input .flag-dropdown:hover .selected-flag:hover {
  background-color: #ededed;
}

.mobile-number-popup-display-title {
  margin-bottom: 5px;
  font-size: 13px;
  color: #828282;
}

.mobile-number-popup-display {
  font-size: 14px;
  color: #333333;
  margin-bottom: 0;
}

.mobile-number-popup-display-wrapper {
  display: flex;
  align-items: baseline;
  margin-bottom: 1.5rem;
}

.mobile-number-popup-display-edit {
  margin-bottom: 0;
  margin-left: 7px;
  font-size: 14px;
  color: #2979ff;
  cursor: pointer;
}

.mobile-number-popup-display-edit:hover {
  text-decoration: underline;
}

.otp-sub-title {
  margin-bottom: 10px;
  font-size: 14px;
  color: #828282;
}

.map-save-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.map-save-wrapper-inner {
  width: 50%;
}

.save-address.map-view {
  width: 100%;
}

.go-back-map-view {
  font-size: 14px;
  cursor: pointer;
}

.go-back-map-view:hover {
  text-decoration: underline;
  color: #2979ff;
}

@media (max-width: 520px) {
  .send-to-verify {
    width: 75%;
  }
}

/* 
.pay-sel-dev button:hover {
  box-shadow: 0 4px 3px 0 rgb(0 0 0 / 10%), 1px 8px 20px 0 rgba(0, 0, 0, 0.19);
}
 */

.pay-sources-drop-down {
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  border-right: 7px solid #ededed;
}

.pay-sources-drop-down:focus {
  border: 1px solid #000;
  box-shadow: none;
}

.pay-sel-dev .selected-payment i {
  font-size: 20px;
  padding: 10px 5px;
}

.pay-sel-dev .selected-payment:focus {
  outline: 0;
  box-shadow: none;
}

.back-btn {
  color: #747474;
  cursor: pointer;
  font-size: 14px;
}

.back-btn:hover {
  color: #000;
}

.edit-card-payment-div {
  font-size: 14px;
  text-align: left;
  margin: 2rem;
}

.edit-card-payment-div-label {
  color: #828282;
  font-size: 13px;
  font-weight: 400;
  display: block;
  text-align: center;
  margin-bottom: 1rem;
}

.edit-card-payment-div .fa-credit-card {
  font-size: 25px;
  color: #ff7068;
  vertical-align: middle;
  margin-right: 10px;
}

.edit-card-payment-div .fa-circle {
  font-size: 4px;
  vertical-align: middle;
  margin-right: 1px;
}

.edit-card-payment-div .fa-pencil-alt {
  color: #ff7068;
}

.edit-card-payment-div .fa-pencil-alt:hover {
  color: #da2b22;
  cursor: pointer;
}

/* card interface */
.add-card-div .add-card {
  color: #fff;
  border: 0;
  width: 55%;
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 0.25rem;
  transition: 0.3s;
  background-color: #2d595b;
}

.add-card-div .add-card:focus {
  outline: 0;
  box-shadow: none;
}

.add-card-div .add-card:hover {
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
}

.add-card-div label {
  margin-top: 8px !important;
}

.payment-method-title {
  text-align: center;
  font-size: 14px;
}

.total-order-info {
  border-top: 1px solid #d4d4d4;
  margin: 4rem 2rem 0 2rem;
  padding-top: 0.7rem;
}

.promo-code-check {
  margin-bottom: 1.5rem;
  color: #006dee;
  cursor: pointer;
  font-size: 13px !important;
}

.promo-code-link {
  font-size: 13px;
}

.promo-code-link:hover {
  text-decoration: underline;
}

.total-order-info .total-sec {
  font-size: 13px;
  font-weight: 400;
  color: #333333;
}

.total-order-info .order-total-wrapper {
  margin-top: 1.5rem !important;
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}

.edit-card-payment-div-inner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-card-payment-div-select {
  width: 80%;
  margin-right: 10px;
}

.edit-card-payment-div-btn {
  width: 20%;
  border: 2px solid #666666;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;
}

.edit-card-payment-div-btn:hover {
  background-color: #e5e5e5;
}

.checkout-pay-button {
  text-align: center;
  margin-top: 1.5rem;
}

.add-card-div {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0;
  margin-right: 0;
}

.save-card-btn {
  margin-top: 0.5rem;
}

/* Add Credit Card */
.eMdWNX {
  display: grid !important;
  position: unset !important;
  margin-left: 0 !important;
}

.eMdWNX:nth-child(3) {
  width: 48%;
  float: left;
}

.eMdWNX:nth-child(4) {
  width: 48%;
  float: right;
}

.eMdWNX .credit-card-input {
  position: unset !important;
  color: #222222;
  background-color: #ededed;
  border: none;
  border-radius: 0.25rem;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.jrjcHa {
  display: block !important;
  position: unset !important;
}

.lkUDGS {
  display: none !important;
}

.cLXUTa {
  display: block !important;
}

.jrjcHa.is-invalid {
  border: none;
}

.bByNba {
  margin: 0;
  margin-bottom: 10px;
}

.pay-select-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pay-select-radio {
  z-index: -1;
  position: absolute;
  opacity: 0;
}
.pay-select-radio:checked ~ label {
  border: 2px solid rgb(5, 147, 153);
  background-color: #e0e0e0;
  color: #000000;
}
.pay-select-radio:checked ~ label:before {
  will-change: transform, border-width, border-color;
  animation: bubble 1s ease-in;
}
.pay-select-radio:checked ~ label:after {
  will-change: opacity, box-shadow;
  animation: sparkles 700ms ease-in-out;
}
.pay-select-radio:checked ~ label > span {
  will-change: transform;
  border: 0;
  background-image: linear-gradient(to top right, #6e89ff, #4363ee);
  animation: radio 400ms cubic-bezier(0.17, 0.89, 0.32, 1.49);
}
.pay-select-radio:checked ~ label > span:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: #fff;
}

.pay-select-label {
  position: relative;
  display: grid;
  align-items: center;
  grid-template-columns: 20px auto 65px;
  grid-gap: 20px;
  width: 320px;
  height: 62px;
  padding: 0 20px;
  border-radius: 6px;
  border: 2px solid transparent;
  background-color: transparent;
  transition: all 300ms ease-in;
  background: #ededed;
  font-weight: 500;
  font-size: 14px;
}
.pay-select-label:hover {
  border-color: #4062f6;
  background-color: rgba(97, 154, 234, 0.16);
}
.pay-select-label:before,
.pay-select-label:after {
  position: absolute;
  left: 29px;
  border-radius: 50%;
  content: "";
}
.pay-select-label:before {
  margin: -2rem;
  border: solid 2rem #545461;
  width: 4rem;
  height: 4rem;
  transform: scale(0);
}
.pay-select-label:after {
  margin: -0.1875rem;
  width: 0.375rem;
  height: 0.375rem;
}
.pay-select-label > span {
  position: relative;
  display: inline-flex;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  border: 2px solid #bdbdbd;
  background-image: linear-gradient(to bottom, #cacaca, #cacaca);
}

.pay-card {
  text-align: right;
}

.pay-card svg {
  width: 45px;
  height: auto;
}

.pay-card img {
  width: 46px;
  height: auto;
}

.option {
  transition: 0.2s;
}

.pay-select-label:hover {
  box-shadow: 0 2px 3px 0 rgb(0 0 0 / 10%), 1px 4px 6px 0 rgb(0 0 0 / 19%)
}

.option:not(:last-child) {
  margin-bottom: 4px;
}

@keyframes radio {
  0%,
  17.5% {
    transform: scale(0);
  }
}

@media (max-width: 520px) {
  .edit-card-payment-div-inner {
    flex-wrap: wrap;
    justify-content: center;
  }

  .edit-card-payment-div-select {
    width: 100%;
  }

  .edit-card-payment-div-btn {
    width: 50%;
    margin-left: 0;
    margin-top: 1rem;
  }

  .total-order-info {
    margin: 3rem 0rem 0 0rem;
  }
}

.centered-button {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.error-code {
  color: #fff;
  text-align: center;
  font-size: 90px;
  font-weight: 900;
  letter-spacing: 8;
}
.error-description {
  color: #fff;
  text-align: center;
  font-size: 30px;
}
.main-container {
  display: flex;
  justify-content: center;
}
.reload-button {
  display: flex;
  margin-top: 50px;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  font-size: 20px;
  color: #fff;
  text-align: center;
  border: 1.5px solid;
  cursor: pointer;
}

.order-status-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.order-status-id {
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: left;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  background-color: #e4e6e7;
  border-radius: 6px;
  padding: 1rem 1rem;
}

.order-status-id h6 {
  font-size: 1em;
  font-weight: 300;
  margin-top: 1.1rem;
  margin-bottom: 2px;
}

.order-status-id h5 {
  font-size: 1.08em;
  font-weight: 600;
}

.order-status-title {
  font-size: 1.1em;
}

.order-status-resturant-name {
  font-size: 2em;
}

.order-status-timeline {
  margin: 2.5rem 2.5rem;
}

.status {
  font-size: 1em;
  font-weight: 600;
  margin-left: 0.5rem;
}

.date-time {
  font-size: 0.9em;
  font-weight: 400;
  margin-left: 0.5rem;
}

/* Status time line */
.time-line {
  display: flex;
  flex-direction: row;
}

.icon-line {
  display: flex;
  flex-direction: column;
}

.detail-line {
  display: flex;
  flex-direction: column;
}

.status-icon {
  border-left: 2px solid #d4d4d4;
  height: 5.5em;
}

.detail-box {
  height: 5.5em;
  text-align: left;
}

.fa-check-circle {
  color: #348bf0;
}

.fa-check-circle::before {
  display: inline-block;
  margin: 0 0 2.5em -0.5em;
  background-color: white;
}

.error-msg h5 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 1.3rem;
  font-weight: 300;
}

@media (max-width: 975px) {
  .order-status-container {
    margin-top: 1rem;
    margin-bottom: 1rem;
    text-align: center;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
  }

  .order-status-id {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: #e4e6e7;
    border-radius: 6px;
    padding: 1rem 1rem;
  }

  .order-status-id h6 {
    font-size: 1em;
    font-weight: 300;
    margin-top: 1.1rem;
    margin-bottom: 2px;
  }

  .order-status-id h5 {
    font-size: 1.08em;
    font-weight: 500;
  }

  .order-status-title {
    font-size: 1.1em;
  }

  .order-status-resturant-name {
    font-size: 1.8em;
  }

  .order-status-timeline {
    margin: 2.5rem 3rem;
  }

  .status {
    font-size: 1em;
    font-weight: 600;
  }

  .date-time {
    font-size: 0.9em;
    font-weight: 400;
  }

  /* Status time line */
  .time-line {
    display: flex;
    flex-direction: row;
  }

  .icon-line {
    display: flex;
    flex-direction: column;
  }

  .detail-line {
    display: flex;
    flex-direction: column;
  }

  .status-icon {
    border-left: 2px solid #d4d4d4;
    height: 5em;
  }

  .detail-box {
    height: 5em;
    text-align: left;
  }

  .fa-check-circle {
    color: #348bf0;
  }

  .fa-check-circle::before {
    display: inline-block;
    margin: 0 0 2.5em -0.5em;
    background-color: white;
  }

  .error-msg h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1.3rem;
    font-weight: 300;
  }
}

@media (max-width: 575px) {
  .order-status-container {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .order-status-id {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: left;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    background-color: #e4e6e7;
    border-radius: 6px;
    padding: 1rem 1rem;
  }

  .order-status-id h6 {
    font-size: 0.9em;
    font-weight: 400;
    margin-top: 1.1rem;
    margin-bottom: 2px;
  }

  .order-status-id h5 {
    font-size: 1em;
    font-weight: 500;
  }

  .order-status-title {
    font-size: 0.9em;
    margin-top: 0.8rem;
  }

  .order-status-resturant-name {
    font-size: 1.4em;
    font-weight: 500;
  }

  .order-status-timeline {
    text-align: left;
    margin: 2rem 3rem;
  }

  .status {
    font-size: 0.9em;
    font-weight: 500;
  }

  .date-time {
    font-size: 0.8em;
    font-weight: 300;
  }

  /* Status time line */
  .time-line {
    display: flex;
    flex-direction: row;
  }

  .icon-line {
    display: flex;
    flex-direction: column;
  }

  .detail-line {
    display: flex;
    flex-direction: column;
  }

  .status-icon {
    border-left: 2px solid #d4d4d4;
    height: 5em;
  }

  .detail-box {
    height: 5em;
    text-align: left;
  }

  .fa-check-circle {
    color: #348bf0;
  }

  .fa-check-circle::before {
    display: inline-block;
    margin: 0 0 2.5em -0.5em;
    background-color: white;
  }

  .error-msg h5 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
    font-weight: 300;
  }
}

